import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DialogActions, DialogContent, Divider, Grid } from '@mui/material';
import ModalCustom from '../Dialog/modalCustom';
import { FormProvider, RHFTextField } from '../hook-form';
import ModalButtonAtom from '../Button/modal-button';

const Clone = (props) => {
  const validationSchema = Yup.object().shape({
    cloneName: Yup.string().required('Clone Name Required'),
  });

  const defaultValues = {
    cloneName: '',
  };

  const onFormSubmit = (data) => {
    props.setOn(false);
  };
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: 'all',
  });

  const {
    reset,
    handleSubmit,
    // formState: { isSubmitting },
  } = methods;

  const onClose = () => {
    props.setOn(false);
    reset();
  };

  const onErrors = (errors) => console.error(errors);

  return (
    <ModalCustom openDialog={props.on} dialogHeading="Clone Sales Process" onClose={onClose}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onFormSubmit, onErrors)}>
        <DialogContent>
          <Grid>
            <RHFTextField name="cloneName" label="Enter Sales Process Name" size="small" />
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <ModalButtonAtom onClose={onClose} onSubmitButton={() => onFormSubmit()} buttonSubmitName="Clone" />
        </DialogActions>
      </FormProvider>
    </ModalCustom>
  );
};
export default Clone;

import './login.screen.css';
import { useEffect, useCallback } from 'react';
import { useAtom } from 'jotai';
import { Amplify, Auth } from 'aws-amplify';
import { styled } from '@mui/material/styles';
import { withAuthenticator, Authenticator } from '@aws-amplify/ui-react';
// eslint-disable-next-line import/no-unresolved
import '@aws-amplify/ui-react/styles.css';

import awsExports from '../aws-exports';
import Routeing from '../routes';
import { isAuth as isAuthAtom, loginedUser, salesProcess } from '../store';
import { register } from './login.service';
import { constants } from '../constants.value';
import { getSalesProcessListData } from '../pages/SalesProcessList/salesprocess.service';

Amplify.configure(awsExports);

function LoginScreen() {
  const [, setIsAuth] = useAtom(isAuthAtom);
  const [, setUserName] = useAtom(loginedUser);
  const [, setSalesProcessData] = useAtom(salesProcess);
  const { RESPONSE_STATUS } = constants;

  const getTokenAuth = useCallback(async () => {
    const data = await Auth.user.attributes;
    localStorage.setItem('satellizer_token', data.sub);
    localStorage.setItem('logined_user', data.name);
    localStorage.setItem('logined_email', data.email);
    setUserName(data.name);
    setIsAuth(data.sub);
    data.firstName = data.name.split(' ')[0];
    data.lastName = data.name.split(' ')[1];
    data.cognitoId = data.sub;
    const response = await register(data);
    if (response.status === RESPONSE_STATUS.SUCCESS_MESSAGE) {
      const _response = await getSalesProcessListData();
      if (_response.status === RESPONSE_STATUS.SUCCESS_MESSAGE) {
        setSalesProcessData(_response.data);
      }
    }
  }, [RESPONSE_STATUS.SUCCESS_MESSAGE, setIsAuth, setSalesProcessData, setUserName]);

  useEffect(() => {
    const token = localStorage.getItem('satellizer_token');
    if (!token && !token?.length) getTokenAuth();
  }, []);

  return (
    <Authenticator>
      <Routeing />
    </Authenticator>
  );
}
export default withAuthenticator(LoginScreen);

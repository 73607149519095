import { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Avatar } from '@mui/material';
import { PropTypes } from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import Checkbox from '@mui/material/Checkbox';
import Delete from '../../components/Modals/Delete';
import { constants } from '../../constants.value';
import { toasterService } from '../../helper/index';
import { deleteCheckpoint as deleteCheckpointApi } from '../../components/Modals/createCheckpoint.service';
import { QmCheckpoints } from './SalesProcessBoard.style';

const Checkpoint = (props) => {
  const { RESPONSE_STATUS } = constants;
  const { checkpointObj, index, getCheckpoint, handleEditCheckpoint } = props;
  const { title, stageId, id: checkId } = checkpointObj;
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleDeleteModal = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  const deleteCheckpoint = async () => {
    const response = await deleteCheckpointApi(checkId, stageId);
    if (response?.status === RESPONSE_STATUS.SUCCESS_MESSAGE) {
      setOpenDeleteModal(false);
      getCheckpoint();
    }else {
      toasterService(response.message);
    }
  };

  return (
    <>
      <Draggable key={checkId} draggableId={checkId} index={index}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            key={checkId}
            style={{
              backgroundColor: snapshot.isDragging && 'white',
              ...provided.draggableProps.style,
            }}
          >
            <QmCheckpoints onClick={
              () => handleEditCheckpoint(checkpointObj)
            }>
              <Checkbox disabled className="checkbox" />
              <div className="checkPoint">{title}</div>
              <div className="deleteIcon">
              <Avatar onClick={
                  (e) => {
                    e.stopPropagation();
                    handleDeleteModal();
                  }
                }>
                  <DeleteIcon />
                </Avatar>
              </div>
            </QmCheckpoints>
            {provided.placeholder}
          </div>
        )
      }
    </Draggable >
    {openDeleteModal && <Delete deleteOpen={openDeleteModal} setDeleteOpen={setOpenDeleteModal} onClick={deleteCheckpoint} title={title} name="checkpoint"/>}
    </>
  );
};

Checkpoint.propTypes = {
  checkpointObj: PropTypes.object,
  getCheckpoint: PropTypes.func,
  handleEditCheckpoint: PropTypes.func,
  index: PropTypes.number
};

export default Checkpoint;

import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { PropTypes } from 'prop-types';
import { styled } from '@mui/material/styles';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, Avatar, Grid, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import InviteCollabrator from '../../pages/InviteCollabrator/InviteCollabrator';
import CreateQM from '../Modals/CreateQM';
import { constants } from '../../constants.value';
import ButtonAtom from '../Button/buttonAtom';
import Popup from '../Popup';
import { FormProvider, RHFTextField } from '../hook-form';
import CustomToolTip from '../Tooltip/CustomToolTip';
import { editSalesProcessRecord, getSalesProcessName as getSalesProcessNameApi } from '../../pages/SalesProcessList/salesprocess.service';
import { getCollaborator } from '../../pages/InviteCollabrator/collaborator.service';
import { toasterService } from '../../helper/index';
import { loginedUser, inviteCollabrator } from '../../store';

const CustomHeader = styled('header')(({ theme }) => ({
  justifyContent: 'space-between',
  width: 'calc(100% - 9%)',
  margin: 'auto',
  maxWidth: '1140px',
  display: 'flex',
  paddingBottom: '40px',
  paddingTop: '40px',
  [theme.breakpoints.up('lg')]: {
    paddingBottom: '25px',
    paddingTop: '25px',
  },
  '& .avatarProfile': {
    backgroundColor: theme.palette.button.outline,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '100%',
    textAlign: 'center',
    color: theme.palette.primary.dark,
    width: '44px',
    height: '44px',
    radius: '22px',
    cursor: 'pointer',
  },
  '& .headerImage': {
    height: '50px',
    left: '70px',
    top: '64px',
    [theme.breakpoints.up('lg')]: {
      height: '40px',
    },
  },
}));

const SalesProcessBoardHeader = styled(Box)(({ theme }) => ({
  marginTop: '41px',
  '& >.MuiGrid-root:nth-of-type(1)': {
    marginBottom: '53px',
  },
  '& .profileIcon': {
    backgroundColor: theme.palette.button.outline,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '100%',
    textAlign: 'center',
    color: theme.palette.primary.dark,
    width: '44px',
    height: '44px',
    radius: '22px',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      width: '38px',
      height: '38px',
      radius: '19px',
      fontSize: '17px',
    },
  },
  '& .img': {
    '& img':{
      height:'100%'
    }
  },
  '& .arrowBackIcon': {
    color: theme.palette.primary.main,
    '& .MuiSvgIcon-root': {
      width: '32px',
      fontWeight: 400,
      fontSize: '40px',
      lineHeight: '100%',
      cursor: 'pointer',
      [theme.breakpoints.down('md')]: {
        width: '26px',
      },
    },
  },
  '& .textFieldSize': {
    width: '249px',
    [theme.breakpoints.down('md')]: {
      marginLeft: '6px',
      width: '169px',
    },
  },
  '& .collaborators': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '15px',
    [theme.breakpoints.down('md')]: {
      gap: '15px',
    },
  },
  '& .collaboratorsIcon': {
    display: 'flex',
    flexWrap: 'nowrap',
    gridColumnGap: '2px',
    '& .MuiAvatar-root': {
      width: 44,
      height: 44,
      color: theme.palette.primary.dark,
      align: 'center',
      fontWeight: '500px',
      fontSize: '20px',
      cursor: 'pointer',
      [theme.breakpoints.down('md')]: {
        width: '38px',
        height: '38px',
        fontSize: '17px',
      },
      '&:hover': {
        boxShadow:
          '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
      },
    },
  },
  '& .MuiIconButton-root': {
    padding: '0px',
    '& .inviteCollaboratorsIcon': {
      backgroundColor: theme.palette.common.white,
      color: `${theme.palette.button.outline} !important`,
      border: `2px solid ${theme.palette.button.outline}`,
      width: 44,
      height: 44,
      [theme.breakpoints.down('md')]: {
        width: '38px',
        height: '38px',
      },
      '&:hover': {
        border: `2px solid ${theme.palette.primary.themeColor}`,
      },
      '& .MuiSvgIcon-root': {
        color: theme.palette.primary.themeColor,
      },
    },
  },
  '& .createQM': {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    '& .MuiButtonBase-root': {
      [theme.breakpoints.down('md')]: {
        fontSize: '13px !important',
        whiteSpace: 'nowrap',
        padding: '2px',
        width: '180px',
      },
    },
  },
  '& .exportBtn': {
    [theme.breakpoints.down('md')]: {
      height: '38px',
      fontSize: '14px',
      lineHeight: '24px',
    },
  },
}));

export default function Header(props) {
  const { qmData, setQmData, openQm, setOpenQm, getAllQm } = props;
  const location = useLocation();
  const name = localStorage.getItem('logined_user');
  const [userName, setUserName] = useAtom(loginedUser || name);
  const navigate = useNavigate();
  const { id } = useParams();
  const { RESPONSE_STATUS } = constants;
  const iSSalesBoard = location.pathname === `/sales-process-board/${id}`;
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const [openInviteCollaboratorModal, setOpenInviteCollaboratorModal] = useState(false);
  const [collaboratorIcon, setCollaboratorIcon] = useState(null);
  const [profileIcon, setProfileIcon] = useState(null);
  const [collaboratorEmail, setcollaboratorEmail] = useAtom(inviteCollabrator);
  const toolTipColors = ['#75ee0a4f', '#06db634d', '#6e94854d', '#fd9daf4d'];

  useEffect(() => {
    if (!userName) setUserName(localStorage.getItem('logined_user'));
  }, [name, setUserName, userName]);

  const defaultValues = {
    salesProcessNameData: '',
  };

  const handleInviteCollaboratorModal = (event) => {
    setOpenInviteCollaboratorModal(true);
    setCollaboratorIcon(event.currentTarget);
  };

  const handleCreateQm = () => {
    setQmData((pre) => ({
      ...pre,
      qmObj: {}
    }));
    setOpenQm((pre) => ({
      ...pre,
      qm: true
    }))
  };

  const handlePopup = (event) => {
    setProfileMenuOpen(true);
    setProfileIcon(event.currentTarget);
  };

  const salesProcessSchema = Yup.object().shape({
    salesProcessNameData: Yup.string().required('Sales Process required'),
  });

  const methods = useForm({
    resolver: yupResolver(salesProcessSchema),
    defaultValues,
  });

  const { handleSubmit, setValue } = methods;

  const getCollaboratorData = async () => {
    const response = await getCollaborator(id);
    if (response && !!response.result) {
      setcollaboratorEmail(response?.result);
    }
  };
  const getSalesProcessName = async () => {
    const response = await getSalesProcessNameApi(id);
    if (response?.status === RESPONSE_STATUS.SUCCESS_MESSAGE) {
      setValue("salesProcessNameData", response.data.salesProcessName);
    }
  };

  useEffect(() => {
    if (iSSalesBoard) {
      getCollaboratorData();
      getSalesProcessName();
    }
  }, []);

  const onSubmit = async (values) => {
    let payload;
    if (values.type === 'blur') {
      payload = {
        salesProcessName: values.target.value,
      };
    }
    values.target.blur();
    const response = await editSalesProcessRecord(id, payload);
    if (response?.code === RESPONSE_STATUS.SUCCESS) {
      toasterService(response.message);
    }
  };

  return (
    <>
      {iSSalesBoard ? (
        <SalesProcessBoardHeader>
          <Grid container item xs={12}>
            <Grid container item xs={6}>
              <Grid container item xs={12}>
                <Grid item xs={1} className="arrowBackIcon">
                  <ArrowBackIcon onClick={() => navigate(-1)} />
                </Grid>
                <Grid item xs={11}>
                  <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <RHFTextField
                      name="salesProcessNameData"
                      label="Sales Process Name"
                      className="textFieldSize"
                      onBlur={(e) => onSubmit(e)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') onSubmit(e)
                      }}
                    />
                  </FormProvider>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={6}>
              <Grid container item xs={12} className="collaborators">
                <Grid item className="collaboratorsIcon">
                  {!!collaboratorEmail &&
                    collaboratorEmail.map(
                      (item, index) =>
                        index <= 4 &&
                        item.isOwner !== true && (
                          <>
                            <CustomToolTip
                              key={index}
                              placement="bottom"
                              name={item.user.firstName}
                              access={item.canEdit ? 'can edit' : 'can view'}
                            >
                              <Avatar sx={{ background: toolTipColors[index] }}>
                                {item.user.firstName?.toUpperCase().slice(0, 1)}
                              </Avatar>
                            </CustomToolTip>
                          </>
                        )
                    )}
                  <IconButton size="small" onClick={handleInviteCollaboratorModal}>
                    <Avatar className="inviteCollaboratorsIcon">
                      <AddIcon />
                    </Avatar>
                  </IconButton>
                </Grid>
                <Grid item>
                  <ButtonAtom
                    buttonType="submit"
                    ButtonName="EXPORT"
                    variant="contained"
                    cursor="pointer"
                    className="exportBtn"
                  />
                </Grid>
                <Grid item>
                  <Avatar className="profileIcon" onClick={handlePopup}>
                    {userName?.toUpperCase().slice(0, 1)}
                  </Avatar>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={6} className='img'>
              <img src="/static/images/meddic_indicator.png" alt="" />
            </Grid>
            <Grid item xs={6} className="createQM">
              <ButtonAtom
                buttonType="submit"
                ButtonName="CREATE NEW Q/M"
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={handleCreateQm}
              />
            </Grid>
          </Grid>
        </SalesProcessBoardHeader>
      ) : (
        <CustomHeader>
          <Link to="/">
            <img src="/static/images/logo.svg" className="headerImage" alt="" />
          </Link>
          <Avatar onClick={handlePopup} className="avatarProfile">
            {userName?.toUpperCase().slice(0, 1)}
          </Avatar>
        </CustomHeader>
      )}
      {profileMenuOpen && (
        <Popup profileMenuOpen={profileMenuOpen} setProfileMenuOpen={setProfileMenuOpen} profileIcon={profileIcon} />
      )}
      {openInviteCollaboratorModal && (
        <InviteCollabrator
          salesProcessId={id}
          openInviteCollaboratorModal={openInviteCollaboratorModal}
          setOpenInviteCollaboratorModal={setOpenInviteCollaboratorModal}
          collaboratorIcon={collaboratorIcon}
        />
      )}
      {openQm && (
        <CreateQM salesProcessId={id} qmData={qmData} openQm={openQm} setOpenQm={setOpenQm} getAllQm={getAllQm} />
      )}
    </>
  );
}

Header.propTypes = {
  qmData: PropTypes.object,
  getAllQm: PropTypes.func,
  setQmData: PropTypes.func,
  openQm: PropTypes.bool,
  setOpenQm: PropTypes.func,
};

import { alpha } from '@mui/material/styles';

// SETUP COLORS
const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#000000de',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8),
};

const PRIMARY = {
  lighter: '#D1E9FC',
  light: '#76B0F1',
  main: '#1C3E72',
  dark: '#000',
  themeColor: "#FCB95B",
  accessText: alpha('#fff', 0.87),
  darker: '#061B64',
  contrastText: '#fff',
  grey: '#757575',
  darkerGrey: '#5E6368',
  red: '#CD3222',
  shadowLight: alpha('#000', 0.25),
  fogGray: '#F3F2F2',
  whiteSmoke: '#0000001a',
  silver: '#00000099',
  greyShade: '#00000021',
  greyLightShade: '#00000026',
  mainLight: '#f1ea6969'
};

const SECONDARY = {
  lighter: '#D6E4FF',
  light: '#84A9FF',
  main: '#3366FF',
  dark: '#1939B7',
  darker: '#091A7A',
  contrastText: '#fff',
};

const BORDERCOLOR = {
  light: alpha('#000', 0.2),
  hoverBorder: '#b9babd',
  lightBorder: alpha('#fff', 0.12),
};
const BUTTONMAIN = {
  outline: alpha('#fcb95b', 0.5),
  lightGreen: "rgba(117, 238, 10, 0.31)",
  lightPink: "rgba(253, 157, 175, 0.3)",
  lightPurple: "rgba(110, 148, 133, 0.3)",
  lightSkyBlue: "rgba(6, 219, 99, 0.3)",
  lightBlack: alpha('#000', 0.87),
  lightGreyBorder: alpha('#000', 0.12),
  lightGrey: alpha('#000', 0.4),
  lightSkinColor: alpha('#fcb95b', 0.38),
}

const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
  contrastText: '#fff',
};

const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#54D62C',
  dark: '#229A16',
  darker: '#08660D',
  contrastText: GREY[800],
};

const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
  contrastText: GREY[800],
};

const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
  contrastText: '#fff',
};

const palette = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY },
  button: { ...BUTTONMAIN },
  border: { ...BORDERCOLOR },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  grey: GREY,
  divider: GREY[500_24],
  text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
  background: { paper: '#fff', default: GREY[0], neutral: GREY[200] },
  action: {
    active: GREY[600],
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export default palette;

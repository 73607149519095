import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { styled } from '@mui/material/styles';

const MainToastContainer = styled('div')(({ theme }) => ({
  '& .Toastify__toast-container': {
    minWidth: 'fit-content',
    width: '184px',
    '& .Toastify__toast': {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
      borderRadius: 4,
      minHeight: '-webkit-fill-available',
      placeContent: 'center',
      '& .Toastify__toast-body': {
        flex: 'initial',
      },
      '& .Toastify__close-button': {
        display: 'none',
      },
    },
  },
}));
export default function Toaster() {
  return (
    <MainToastContainer>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        transition={Slide}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        pauseOnHover
      />
    </MainToastContainer>
  );
}

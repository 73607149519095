import { constants } from '../../constants.value';
import { httpService } from '../../helper';

export function getStages(id) {
  return httpService()
    .get(`${constants.API.STAGE}/${id}`)
    .then((response) => response.data)
    .catch((error) => error.response);
}

export function createNewStage(data) {
  return httpService()
    .post(`${constants.API.STAGE}`, data)
    .then((response) => response.data)
    .catch((error) => error.response);
}

export function editStage(id, data) {
  return httpService()
    .put(`${constants.API.STAGE}/${id}`, data)
    .then((response) => response)
    .catch((error) => error.response);
}

export function deleteStage(id, data) {
  return httpService()
    .delete(`${constants.API.STAGE}/${id}?salesProcessId=${data}`)
    .then((response) => response.data)
    .catch((error) => error.response);
}

export function updateCheckpointOrder(data) {
  return httpService()
    .put(`${constants.API.CHECKPOINT}`, data)
    .then((response) => response)
    .catch((error) => error.response);
}

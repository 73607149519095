import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Autocomplete, Chip, FormControl, TextField } from '@mui/material';
// ----------------------------------------------------------------------

RHFAutocomplete.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  handleChange: PropTypes.func,
  options: PropTypes.array,
  InputProps: PropTypes.node,
};

export function RHFAutocomplete({ name, label, handleChange, options, onBlur, InputProps, ...other }) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          options={options || []}
          multiple
          name={name || ''}
          freeSolo
          getOptionLabel={(option) => option.email}
          onChange={(e, data) => field.onChange(data)}
          onBlur={onBlur || field.onBlur}
          value={field.value || []}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              onChange={handleChange}
              InputProps={{
                ...params.InputProps,
                endAdornment: <>{InputProps}</>,
              }}
              label={label}
              error={!!error}
              helperText={error?.message}
            />
          )}
          renderTags={(value, getTagProps) =>
            (value || []).map((option, index) => (
              <FormControl key={index}>
                <Chip variant="outlined" label={option.email || option} {...getTagProps({ index })} />
              </FormControl>
            ))
          }
          {...other}
        />
      )}
    />
  );
}
RHFAutocompleteSingle.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  options: PropTypes.array,
};

export function RHFAutocompleteSingle({ name, label, options, onBlur, ...other }) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          id={name}
          disablePortal
          options={options || []}
          onChange={(e, data) => field.onChange(data)}
          sx={{ maxWidth: '100%' }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onBlur={onBlur || field.onBlur}
          getOptionLabel={(option) => option.stageName || option.qmName || ''}
          value={field.value}
          className="autoComplete"
          renderInput={(params) => (
            <TextField {...params} name={name} label={label} size="small" error={!!error} helperText={error?.message} />
          )}
          {...other}
        />
      )}
    />
  );
}

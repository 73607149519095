import { DialogActions, DialogContent, Divider, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { FormProvider, useForm } from 'react-hook-form';
import ModalButtonAtom from '../Button/modal-button';
import ModalCustom from '../Dialog/modalCustom';

export const PopupGrid = styled(Grid)(() => ({
  padding: '16px 55px',
  gap: '8px',
  justifyContent: 'center',
}));

const Delete = (props) => {
  const { deleteOpen, setDeleteOpen, onClick, title, name} = props;

  const onFormSubmit = () => {
    onClick();
  };

  const { methods, handleSubmit, reset } = useForm({
    mode: 'all',
  });

  const onClose = () => {
    setDeleteOpen(false);
    if (props?.setStageData) props.setStageData({});
    reset();
  };

  const onErrors = (errors) => console.error(errors);
  return (
    <ModalCustom
      isDeleteModal
      openDialog={deleteOpen}
      dialogHeading="Are you sure you want to delete?"
      onClose={onClose}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onFormSubmit, onErrors)}>
        <DialogContent>
          <PopupGrid>
            <p>You will not be able to recover the {name} <b>"{title}"</b> after deleting it.</p>
          </PopupGrid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <ModalButtonAtom
            onClose={onClose}
            isDeleteModal
            onSubmitButton={() => onFormSubmit()}
            buttonSubmitName="Delete"
          />
        </DialogActions>
      </FormProvider>
    </ModalCustom>
  );
};

Delete.propTypes = {
  deleteOpen: PropTypes.bool,
  setDeleteOpen: PropTypes.func,
  onClick: PropTypes.func,
  setStageData: PropTypes.func,
};

export default Delete;

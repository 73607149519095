import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DialogActions, DialogContent, Divider, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ModalCustom from '../Dialog/modalCustom';
import { FormProvider, RHFTextField } from '../hook-form';
import { constants } from '../../constants.value';
import { createNewSalesProcess } from '../../pages/SalesProcessList/salesprocess.service';
import ModalButtonAtom from '../Button/modal-button';

const CreateSales = (props) => {
  const {open, setOpen, getData} = props;
  const { RESPONSE_STATUS } = constants;
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    salesName: Yup.string().trim().required('Sales Process Name Required'),
  });

  const defaultValues = {
    salesName: '',
  };

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: 'all',
  });

  const {
    reset,
    handleSubmit,
    setError,
    // formState: { isSubmitting },
  } = methods;

  const onClose = () => {
    setOpen(false);
    reset();
  };

  const onSubmit = async (values) => {
    if (values && !!values) {
      const payload = {
        salesProcessName: values?.salesName,
      };
      const response = await createNewSalesProcess(payload);
      if (response?.status === RESPONSE_STATUS.SUCCESS_MESSAGE) {
        navigate('/sales-process-list');
        onClose();
        getData();
      } else {
        setError('salesName', { message: response.message }, { type: 'focus' }, { shouldFocus: true });
      }
    }
  };
  const onErrors = (errors) => console.error(errors);
  return (
    <ModalCustom
      openDialog={open}
      dialogHeading="Create New Sales Process"
      onClose={onClose}
      onClickIconButton={onClose}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit, onErrors)}>
        <DialogContent>
          <Grid>
            <RHFTextField name="salesName" label="Sales Process Name" size="small" />
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <ModalButtonAtom onClose={onClose} onSubmitButton={() => onSubmit()} buttonSubmitName="Create" />
        </DialogActions>
      </FormProvider>
    </ModalCustom>
  );
};

export default CreateSales;

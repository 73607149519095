// ----------------------------------------------------------------------

export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          padding: '2px 16px',
          lineHeight: "36px",
          boxShadow: "none",
          display: "flex",
          alignItems: "center",
          letterSpacing: "1.25px",
          textTransform: "uppercase",
          fontWeight: 500,
          fontSize: 14,
          '&:hover': {
            boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
          },
          '&.Mui-disabled': {
            border: 'none',
            padding: '4px 20px',
          },
        },
        sizeLarge: {
          height: 48,
        },
        containedInherit: {
          boxShadow: theme.customShadows.z8,
          '&:hover': {
            backgroundColor: theme.palette.grey[400],
          },
        },
        containedPrimary: {
          backgroundColor: theme.palette.primary.themeColor,
          color: theme.palette.primary.contrastText,
          padding: '4px 20px',
          '&:hover': {
            backgroundColor: theme.palette.primary.themeColor,
          },
        },
        outlinedPrimary: {
          border: `2px solid ${theme.palette.button.outline}`,
          color: theme.palette.primary.themeColor,
          '&:hover': {
            border: `2px solid ${theme.palette.primary.themeColor}`,
            boxShadow: 'none',
            backgroundColor: 'transparent'
          },
        },
        containedSecondary: {
          boxShadow: theme.customShadows.secondary,
        },
        outlinedInherit: {
          border: `1px solid ${theme.palette.primary.themeColor}`,
          '&:hover': {
            backgroundColor: "#ffb349",
            border: 0,
          },
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
    },
  };
}

import { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useAtom } from 'jotai';
import { Navigate, Route, Routes } from 'react-router-dom';
// layouts
// import DashboardLayout from './layouts/dashboard';
// import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
// import Blog from './pages/Blog';
// import User from './pages/User';
// import Login from './pages/Login';
import NotFound from './pages/Page404';
// import Register from './pages/Register';
// import Products from './pages/Products';
// import DashboardApp from './pages/DashboardApp';
import Page from './components/Page';
import SalesProcess from './pages/SalesProcessList/SalesProcessList'
// import RegisterForm from './pages/FormComponent';
// import Main from './pages/Main';
import LandingPage from './pages/LandingPage';
import { ResetPassword } from './sections/auth/forget-password';
import { isAuth as isAuthAtom, salesProcess } from './store';
import { getSalesProcessListData as getSalesProcessListDataApi } from './pages/SalesProcessList/salesprocess.service';
import SalesProcessBoard from './pages/SalesProcessBoard/SalesProcessBoard';
import { constants } from './constants.value';

// ----------------------------------------------------------------------
export default function Router() {
  const { RESPONSE_STATUS } = constants;
  const token = localStorage.getItem('satellizer_token');
  const [isAuth, setIsAuth] = useAtom(isAuthAtom);
  const [salesProcessData, setSalesProcessData] = useAtom(salesProcess);

  useEffect(() => {
    if (!isAuth) setIsAuth(localStorage.getItem('satellizer_token'));
  }, [token]);

  const getSalesProcessListData = useCallback(async () => {
    const _response = await getSalesProcessListDataApi();
    if (_response?.status === RESPONSE_STATUS.SUCCESS_MESSAGE && _response.data.length) {
      setSalesProcessData(_response.data);
    }
  }, []);

  useEffect(() => {
    if (!salesProcessData?.length) getSalesProcessListData();
  }, [getSalesProcessListData, salesProcessData]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <Page
              component={salesProcessData?.length ? SalesProcess : LandingPage}
              title={salesProcessData?.length ? 'Sales Process List' : 'Landing Pages'}
            />
          </ProtectedRoute>
        }
      />
      <Route
        path="/reset-password"
        element={
          <ProtectedRoute>
            <Page component={ResetPassword} title="Reset Password" />
          </ProtectedRoute>
        }
      />
      <Route
        path="/sales-process-list"
        element={
          <ProtectedRoute>
            <Page component={SalesProcess} title="Sales Process List" />
          </ProtectedRoute>
        }
      />
      <Route
        path="/sales-process-board/:id"
        element={
          <ProtectedRoute>
            <Page component={SalesProcessBoard} title="Sales Process Board" />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<Navigate to="/404" replace />} />
      <Route path="/404" element={<Page component={NotFound} title="Not Found" />} />
    </Routes>
  );
}
const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem('satellizer_token');
  if (!token) {
    return <Navigate to="/" replace />;
  }

  return children;
};

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DialogActions, DialogContent, Divider, Grid } from '@mui/material';
import ModalCustom from '../Dialog/modalCustom';
import { FormProvider, RHFTextField } from '../hook-form';
import { constants } from '../../constants.value';
import ModalButtonAtom from '../Button/modal-button';
import { createNewStage, editStage } from '../../pages/SalesProcessBoard/sales-process-board.service';

const CreateStage = (props) => {
  const { order, salesProcessId, setOpend, opend, getStages, stageData, setStageData, getAllQm, getCheckpoint } = props;
  const {stageId, stageName} = stageData;
  const { RESPONSE_STATUS } = constants;
  const validationSchema = Yup.object().shape({
    stageName: Yup.string().required('Stage Name Required'),
  });

  const defaultValues = {
    stageName: stageId ? stageName : '',
  };

  const onFormSubmit = async (data) => {
    if (data && !!data) {
      if (stageId) {
        const payload = {
          stageName: data?.stageName,
          salesProcessId,
        };
        const response = await editStage(stageId, payload);
        if (response?.data?.status === RESPONSE_STATUS.SUCCESS_MESSAGE) {
          onClose();
          getStages();
          getAllQm();
          getCheckpoint();
        } else {
          setError('stageName', { message: response.message }, { type: 'focus' }, { shouldFocus: true });
        }
      } else {
        const payload = {
          stageName: data?.stageName,
          order,
          salesProcessId,
        };
        const response = await createNewStage(payload);
        if (response?.status === RESPONSE_STATUS.SUCCESS_MESSAGE) {
          onClose();
          getStages();
        } else {
          setError('stageName', { message: response.message }, { type: 'focus' }, { shouldFocus: true });
        }
      }
    }
  };

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: 'all',
  });

  const {
    reset,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = methods;

  const onClose = () => {
    setOpend((pre) => ({
      ...pre,
      stage: false
    }))
    setStageData({});
    reset();
  };

  const onErrors = (errors) => console.error(errors);
  return (
    <ModalCustom
      openDialog={opend}
      dialogHeading={stageId ? 'Edit Stage' : 'Create New Stage'}
      onClose={onClose}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onFormSubmit, onErrors)}>
        <DialogContent>
          <Grid>
            <RHFTextField name="stageName" label="Stage Name" />
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <ModalButtonAtom
            onClose={onClose}
            isSubmitting={isSubmitting}
            onSubmitButton={() => onFormSubmit()}
            buttonSubmitName={stageId ? 'Save' : 'Create'}
          />
        </DialogActions>
      </FormProvider>
    </ModalCustom>
  );
};

export default CreateStage;

// ----------------------------------------------------------------------

export default function Tooltip(theme) {
  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: theme.palette.primary.main,
          borderRadius: '4px',
          margin: '0px',
          padding: '1px'
        },
        arrow: {
          color: theme.palette.primary.main
        },
      },
    },
  };
}

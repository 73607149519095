/* eslint-disable no-nested-ternary */
import { Typography } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

const CustomTooltip = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '3px 8px',
  gap: '16px',
  justifyContent: 'space-between',
  '& .collabratorName': {
    fontSize: '14px',
    lineHeight: '22px',
    color: theme.palette.common.white,
    letterSpacing: '0.1px',
  },
  '& .collabratorEdit': {
    border: `1px solid ${theme.palette.border.lightBorder}`,
    borderRadius: '25px',
    padding: '4px 9px',
    display: 'flex',
    color: theme.palette.primary.accessText,
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '20px',
  },
}));

const TruncateTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  () => ({
    [`& .${tooltipClasses.tooltip}`]: {
      padding: '3px 8px',
      fontSize: '12px',
      fontWeight: 400,
    },
  })
);

const CustomToolTip = ({ children, placement, name, access, isTooptip, id, TooltipArray }) => (
  <>
    {access ? (
      <Tooltip
        placement={placement}
        title={
          <CustomTooltip>
            <Typography className="collabratorName">{name}</Typography>
            <Typography className="collabratorEdit">{access}</Typography>
          </CustomTooltip>
        }
      >
        {children}
      </Tooltip>
    ) : isTooptip ? (
      <>
        <Tooltip
          placement={placement}
          // eslint-disable-next-line react/prop-types
          title={TooltipArray?.map(
            (shareUser, index) =>
              id !== shareUser.userId && (
                <>
                  <CustomTooltip key={index}>
                    <Typography className="collabratorName">{shareUser.user.firstName}</Typography>
                    <Typography className="collabratorEdit">{shareUser.canEdit ? 'can edit' : 'can view'}</Typography>
                  </CustomTooltip>
                </>
              )
          )}
        >
          {children}
        </Tooltip>
      </>
    ) : (
      <TruncateTooltip placement={placement} title={name}>
        {children}
      </TruncateTooltip>
    )}
  </>
);

CustomToolTip.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string,
  access: PropTypes.string,
  placement: PropTypes.string.isRequired,
  isTooptip: PropTypes.bool,
  TooltipArray: PropTypes.array,
};

CustomToolTip.defaultProps = {
  name: '',
  access: '',
};

export default CustomToolTip;

// ----------------------------------------------------------------------

export default function Autocomplete(theme) {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          boxShadow: theme.shadows[8],
          borderRadius: '4px',
        },
        listbox: {
          '& .MuiAutocomplete-option[aria-selected="true"]': {
            background: theme.palette.button.lightGreyBorder,
          },
          '& .MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
            background: theme.palette.button.lightGreyBorder,
          },
          '& .MuiAutocomplete-option.Mui-focused': {
            background: theme.palette.button.lightGreyBorder,
          },
          '& :hover, & .Mui-focused': {
            background: theme.palette.button.lightGreyBorder,
          },
        },
        option:{
          marginBottom: '1px',
        }
      },
    }
  }
}


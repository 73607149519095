import { constants } from '../../constants.value';
import { httpService } from '../../helper';

export function createNewSalesProcess(data) {
    return httpService()
        .post(`${constants.API.SALES_PROCESS}`, data)
        .then((response) => response.data)
        .catch((error) => error.response);
};

export function getSalesProcessListData(queryPagination) {
    const keys = `${!!queryPagination && Object.keys(queryPagination).length && `?limit=${queryPagination.limit}&offset=${queryPagination.offset}&search=${queryPagination.searchValue}&order=${queryPagination?.orderSort}`}`
    return httpService()
        .get(`${constants.API.SALES_PROCESS}${keys !== 'false' ? keys : ''}`)
        .then((response) => response.data)
        .catch((error) => error.response);
}

export function deleteSalesProcessRecord(id) {
    return httpService()
        .delete(`${constants.API.SALES_PROCESS}/${id}`)
        .then((response) => response.data)
        .catch((error) => error.response)
}

export function editSalesProcessRecord(id, data) {
    return httpService()
        .put(`${constants.API.SALES_PROCESS}/${id}`, data)
        .then((response) => response.data)
        .catch((error) => error.response)
}

export function getSalesProcessName(id) {
    return httpService()
        .get(`${constants.API.SALES_PROCESS}/${id}`)
        .then((response) => response.data)
        .catch((error) => error.response);
}

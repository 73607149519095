import { constants } from '../../constants.value';
import { httpService } from '../../helper';

export function getCollaborator(id) {
    return httpService()
        .get(`${constants.API.COLLABORATOR}/${id}`)
        .then((response) => response.data)
        .catch((error) => error.response);
};

export function inviteNewCollabotaor(id, data) {
    return httpService()
        .post(`${constants.API.COLLABORATOR}/${id}`, data)
        .then((response) => response.data)
        .catch((error) => error.response);
};

export function editAccess(id,data) {
    return httpService()
        .put(`${constants.API.COLLABORATOR}/${id}`,data)
        .then((response) => response.data)
        .catch((error) => error.response);
};

export function removeCollaborator(queryObject) {
    return httpService()
        .delete(`${constants.API.COLLABORATOR}/${queryObject.id}?salesProcessId=${queryObject.salesProcessId}`)
        .then((response) => response.data)
        .catch((error) => error.response);
};

export function getUserByData(data) {
    return httpService()
        .get(`${constants.API.USERS}?search=${data}`)
        .then((response) => response.data)
        .catch((error) => error.response);
}

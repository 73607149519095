import * as React from 'react';
import { Box, InputLabel, MenuItem, FormControl, Select, FormHelperText } from '@mui/material';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { useFormContext, Controller } from 'react-hook-form';
import ListItemIcon from '@mui/material/ListItemIcon';
import Check from '@mui/icons-material/Check';

RHFSelectbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  menus: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func,
  languageDropdown: PropTypes.bool,
  isInviteModal: PropTypes.bool,
  isCheckpoint: PropTypes.bool,
};

export default function RHFSelectbox({ name, label, isInviteModal, error, isCheckpoint, menus, onChange, languageDropdown }) {
  const { control } = useFormContext();

  const CustomInputLabel = React.useMemo(
    () =>
      styled(InputLabel)(() => ({
        lineHeight: '10px !important',
        '&.MuiInputLabel-shrink': {
          lineHeight: 'inherit !important',
        },
      })),
    []
  );

  const CustomListItemIcon = styled(ListItemIcon)(({ theme }) => ({
    '& .checkTick': {
      color: theme.palette.primary.themeColor,
      fontSize: '18px',
      marginLeft: '15px',
    },
  }));

  const CustomImage = styled('img')(({ theme }) => ({
    backgroundColor: theme.palette.primary.shadowLight,
    marginRight: '16px',
    height: '30px',
    width: '30px',
    padding: '4px',
  }));

  const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
    '&.MuiButtonBase-root': {
      '&.MuiMenuItem-root': {
        marginBottom: '1px',
        '&:hover, &.Mui-selected': {
          background: theme.palette.button.lightGreyBorder,
        },
      }
    }
  }));

  const Item = React.useMemo(
    () =>
      styled(Select)(({ theme }) => ({
        color: languageDropdown ? `${theme.palette.common.white} !important` : null,
        width: languageDropdown ? '110px' : null,
        height: languageDropdown ? '42px' : null,
        '& svg': {
          fill: languageDropdown ? `${theme.palette.common.white} !important` : null,
        },
        '& img': { display: 'none' },
        '& fieldset': {
          borderColor: languageDropdown ? `${theme.palette.common.white} !important` : null,
          borderWidth: languageDropdown ? '1px !important' : null,
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
      })),
    []
  );
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Box>
          <FormControl fullWidth error={!!error}>
            <CustomInputLabel id="demo-simple-select-label">{label}</CustomInputLabel>
            <Item
              {...field}
              value={isInviteModal ? !!field?.value : field?.value}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label={label}
              displayEmpty
              onChange={(...args) => {
                if (onChange && typeof onChange === 'function') {
                  onChange(...args);
                }
                field.onChange(...args);
              }}
            >
              {menus.length
                ? menus.map((item) =>
                  // eslint-disable-next-line no-nested-ternary
                  isInviteModal ? (
                    <CustomMenuItem key={item.value + item.name} value={!!item.value}>
                      {item.name}
                      {!!item.value === !!field.value && (
                        <CustomListItemIcon>
                          <Check className="checkTick" />
                        </CustomListItemIcon>
                      )}
                    </CustomMenuItem>
                  ) : isCheckpoint ? (
                    <CustomMenuItem key={item.value + item.name} value={item.value || ''}>
                      <CustomImage src={item.icon} alt="icon" />
                      {item.name}
                    </CustomMenuItem>
                  ) : (
                    <CustomMenuItem key={item.value + item.name} value={item.value || ''}>
                      {item.name}
                    </CustomMenuItem>
                  )
                )
                : ''}
            </Item>
            {error ? <FormHelperText>{error}</FormHelperText>
              : null}

          </FormControl>
        </Box>
      )}
    />
  );
}

/* eslint-disable no-nested-ternary */
import { Grid, Typography, Box, DialogContent, DialogActions, Divider } from '@mui/material';
import * as Yup from 'yup';
import { styled } from '@mui/material/styles';
import { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { constants } from '../../constants.value';
import ModalCustom from '../Dialog/modalCustom';
import ModalButtonAtom from '../Button/modal-button';
import { FormProvider, RHFCheckbox, RHFSelectbox, RHFTextField } from '../hook-form';
import { createQm, updateQm } from './createQm.service';

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  () => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'transparent',
    },
  })
);

const QMFormAtom = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItem: 'baseline',
  justifyContent: 'flex-start',
  '& .rightInfoQmModal': {
    marginLeft: '15px',
    '& .viewText': {
      padding: '8px 0px !important',
      color: theme.palette.primary.main,
      textAlign: 'left',
    },
    '& .exampleImage img': {
      height: 109
    },
    '& .watchVideoTutorial': {
      marginTop: '30%',
      '& .watchVideo': {
        padding: '8px 0px !important',
        color: theme.palette.primary.main,
        textAlign: 'left',
      },
    },
  },
  '& .leftFormQmModal': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    minWidth: '50%',
    '&> .MuiBox-root': {
      width: 'calc(100% - 20px)',
      margin: '12px 0px',
      '&.checkboxContainer': {
        margin: '15px 0px 0px 0px'
      },
      '& .MuiTypography-root': {
        textAlign: 'left',
        fontSize: '14px',
        fontWeight: 400,
        color: theme.palette.button.lightGrey,
        padding: 0,
      },
      '& .MuiFormControl-root': {
        '& .MuiFormLabel-root': {
          fontSize: '16px',
          color: theme.palette.primary.darkerGrey,
          overflow: 'visible',
          lineHeight: 'inherit',
        },
      },
      '& .qmTypeSelectContent': {
        padding: '5px 12px !important',
        display: 'flex',
        fontSize: '12px',
        marginBottom: '-17px',
        color: theme.palette.primary.main,
        letterSpacing: '0.1px',
      },
      '& .tooltipIcon': {
        color: theme.palette.primary.main,
        fontSize: '18px',
        marginLeft: '10px',
      },
      '& .checkboxTitle': {
        marginLeft: '20px',
        display: 'flex',
        placeItems: 'center',
        '& .MuiFormControlLabel-root': {
          '& .MuiButtonBase-root': {
            padding: 0,
          },
          '& .MuiTypography-root': {
            textAlign: 'left',
            fontSize: '16px',
            fontWeight: 400,
            color: theme.palette.primary.silver,
            padding: 0,
            marginLeft: '4px',
          },
          '& .Mui-checked': {
            color: theme.palette.primary.themeColor,
          }
        },
        '& .tooltipIcon': {
          color: theme.palette.primary.main,
          fontSize: '18px',
          position: 'relative',
          right: '14px',
        },
      },
    },
    '& .tutorialLink': {
      marginTop: '30px',
    },
  },
}));

const CreateQM = (props) => {
  const { openQm, setOpenQm, salesProcessId, qmData, getAllQm } = props;
  const { id: qmId } = qmData
  const { RESPONSE_STATUS } = constants;

  const order = [
    { name: 1, value: 1 },
    { name: 2, value: 2 },
    { name: 3, value: 3 },
    { name: 4, value: 4 },
    { name: 5, value: 5 },
    { name: 6, value: 6 },
  ];
  const menu = [
    { name: 'Qualifiers', value: 'Qualifier' },
    { name: 'Milestones', value: 'Milestones' },
  ];

  const validationSchema = Yup.object().shape({
    qmName: Yup.string().required('Q/M Name Required'),
    qmAbbreviation: Yup.string().required('Q/M Abbreviation Required'),
    type: Yup.string().required('Select a Type').nullable(),
    tutorialLink: Yup.string().required('Tutorial Link Required'),
  });

  const defaultValues = {
    qmName: '',
    qmAbbreviation: '',
    type: '',
    tutorialLink: '',
    forecastIndicator: false,
    forecastIndicatorOrder: null,
    dealOverview: false,
    dealOverviewOrder: null,
  };

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: 'all',
  });

  const {
    watch,
    reset,
    handleSubmit,
    setError,
    setValue,
    formState: { isSubmitting, errors },
  } = methods;

  useEffect(() => {
    if (qmId) {
      Object.keys(qmData).forEach(ele => setValue(`${ele}`, qmData[ele]))
    }
  }, [])

  const onFormSubmit = async (data) => {
    if (data && !!data) {
      const payload = {
        qmName: data?.qmName,
        qmAbbreviation: data?.qmAbbreviation,
        tutorialLink: data?.tutorialLink,
        type: data?.type,
        forecastIndicator: data?.forecastIndicator,
        forecastIndicatorOrder: data?.forecastIndicatorOrder,
        dealOverview: data?.dealOverview,
        dealOverviewOrder: data?.dealOverviewOrder,
        salesProcessId,
      };
      if (qmId) {
        const response = await updateQm(qmId, payload);
        if (response?.status === RESPONSE_STATUS.SUCCESS_MESSAGE) {
          onClose();
          getAllQm();
        } else {
          setError('qmName', { message: response.message }, { type: 'focus' }, { shouldFocus: true });
        }
      } else {
        const response = await createQm(payload);
        if (response?.status === RESPONSE_STATUS.SUCCESS_MESSAGE) {
          onClose();
          getAllQm();
        } else {
          setError('qmName', { message: response.message }, { type: 'focus' }, { shouldFocus: true });
        }
      }
    }
  };
  const onClose = () => {
    setOpenQm((pre) => ({
      ...pre,
      qm: false
    }))
    reset();
  };

  const watchForecast = watch('forecastIndicator');
  const watchDeal = watch('dealOverview');
  const watchQmType = watch('type');
  const onErrors = (errors) => console.error(errors);

  return (
    <ModalCustom
      openDialog={openQm}
      isMultiGridForm
      dialogHeading={qmId ? 'Edit Q/M' : 'Create Q/M'}
      onClose={onClose}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onFormSubmit, onErrors)}>
        <DialogContent>
          <QMFormAtom>
            <Box className="leftFormQmModal">
              <Box>
                <RHFTextField name="qmName" label="Q/M Name" size="small" />
              </Box>
              <Box>
                <RHFTextField name="qmAbbreviation" label="Q/M Abbreviation" size="small" />
              </Box>
              <Box>
                <RHFSelectbox name="type" label="Type" menus={menu} error={errors.type?.message} />
                {watchQmType === 'Qualifier' && (
                  <>
                    <Typography className="qmTypeSelectContent">Qualification Items
                      <InfoOutlinedIcon className="tooltipIcon" /></Typography>
                  </>
                )}
                {watchQmType === 'Milestones' && (
                  <>
                    <Typography className="qmTypeSelectContent">Interaction with Client
                      <InfoOutlinedIcon className="tooltipIcon" /></Typography>
                  </>
                )}
              </Box>

              <Box className='checkboxContainer'>
                <div className="checkboxTitle">
                  <RHFCheckbox name="forecastIndicator" label="Show on Forecast Indicator" />
                  <LightTooltip
                    placement="right-end"
                    title={<img src="/static/images/ForecastOverview.png" alt="" />}
                  >
                    <InfoOutlinedIcon className="tooltipIcon" />
                  </LightTooltip>
                </div>
                {watchForecast && (
                  <Box mt={2}>
                    <RHFSelectbox name="forecastIndicatorOrder" label="Forecast Image Order" menus={order} />
                  </Box>
                )}
              </Box>

              <Box className='checkboxContainer'>
                <div className="checkboxTitle">
                  <RHFCheckbox name="dealOverview" label="Show on Deal Overview" />
                  <LightTooltip
                    placement="right"
                    title={<img src="/static/images/dealOverview.png" alt="" height={270} width={220} />}
                  >
                    <InfoOutlinedIcon className="tooltipIcon" />
                  </LightTooltip>
                </div>
                {watchDeal && (
                  <Box mt={2}>
                    <RHFSelectbox name="dealOverviewOrder" label="Overview Order" menus={order} />
                  </Box>
                )}
              </Box>
              <Box className="tutorialLink">
                <RHFTextField name="tutorialLink" label="Tutorial Link" size="small" />
              </Box>
            </Box>
            <Grid container xs={6} className="rightInfoQmModal">
              <Grid item xs={12} >
                <Typography variant="body2" className="viewText">
                  Example of how CP will look like:
                </Typography>
              </Grid>
              <Grid item xs={12} className="exampleImage" >
                {!watchQmType ? <img src="/static/images/defaultQM.svg" alt="default" /> :
                  watchQmType === 'Qualifier' ? <img src="/static/images/qualifier.svg" alt="Qualifiers" /> :
                    watchQmType === 'Milestones' && <img src="/static/images/Milestone.svg" alt="Milestone" />}
              </Grid>
              <Grid item container className="watchVideoTutorial">
                <Grid item xs={12}>
                  <Typography className="watchVideo" variant="body2">
                    Watch the video to see how it works.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <img src="/static/images/tutorialvideo.png" alt="Some video" />
                </Grid>
              </Grid>
            </Grid>
          </QMFormAtom>
        </DialogContent>
        <Divider />
        <DialogActions>
          <ModalButtonAtom
            onClose={onClose}
            isSubmitting={isSubmitting}
            onSubmitButton={() => onFormSubmit()}
            buttonSubmitName={qmId ? 'Save' : 'Create'}
          />
        </DialogActions>
      </FormProvider>
    </ModalCustom>
  );
};

CreateQM.propTypes = {
  qmData: PropTypes.object,
  getAllQm: PropTypes.func,
  salesProcessId: PropTypes.string,
  openQm: PropTypes.bool,
  setOpenQm: PropTypes.func
};

export default CreateQM;

export const constants = {
  // https://sales-process-api.hl1.in/api/v1/register
  API_BASE: `${
    process.env.NODE_ENV === 'production' ? 'https://sales-process-api.hl1.in' : 'http://localhost:8001'
  }/api/`,
  API_VERSION: 'v1',
  PAGINATION_CURRENT_PAGE: 1,
  PAGINATION_ITEM_PER_PAGE: 10,
  PAGINATION_SELECTED_ITEMS: 0,
  PAGINATION_MAXSIZE: 3,
  ITEM_PER_PAGES: (count) => {
    if (count <= 10) {
      return [];
    }
    if (count <= 25) {
      return [10, 25];
    }
    if (count <= 50) {
      return [10, 25, 50];
    }
    return [10, 25, 50, 100];
  },
  // UserStatus: {
  //     PENDING_APPROVAL: 'Confirmed email, pending admin review',
  //     APPROVED: 'Approved',
  //     VERIFIED: 'Verified',
  //     PENDING_EMAIL: 'Pending Email Verification',
  //     EMAIL_CONFIRMED: 'Confirmed email'
  // },
  API: {
    REGISTER: 'register',
    SALES_PROCESS: 'sales-process',
    COLLABORATOR: 'collaborator',
    STAGE: 'stage',
    USERS: 'users',
    QM: 'qualifier-milestones',
    CHECKPOINT: 'checkpoint',
  },

  // Security message
  REGISTRATION_SUCCESS_MESSAGE: 'Take rest, we will notify you after administration verification.',
  UNAUTHORIZED_ACCESS: 'Unauthorized Access',
  PASSWORD_NOT_SECURE_TRY_OTHER: 'Password Not Secure Try Other',
  PASSWORD_NOT_MATCHED: 'Password not matched',
  INVALID_RECOVERY_CODE: 'Invalid Recovery Code',

  // User message
  CREATE_USER: 'Create User',
  UPDATE_USER: 'Update User',
  YOU_ARE_ALREADY_LOGGED_IN: 'You are already logged in',
  DELETE_YOURSELF: 'Deleting yourself is not allowed',

  RESPONSE_STATUS: {
    SUCCESS: 1000,
    SUCCESS_MESSAGE: 'SUCCESS',
  },
};

import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import PropTypes from "prop-types";

import { useFormContext, Controller } from "react-hook-form";

RHFRadio.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  labels: PropTypes.array.isRequired,
};

export default function RHFRadio({ name, title, labels, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <>
          <FormLabel
            id="demo-radio-buttons-group-label"
            sx={{ marginBottom: "10px" }}
          >
            {title}
          </FormLabel>
          <RadioGroup
            {...field}
            name={name}
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            sx={{ flexDirection: "row", marginTop: "0 !important" }}
          >
            {labels.map((item) => (
              <FormControlLabel
                key={item.name + item.value}
                value={item.value || false}
                control={<Radio />}
                label={item.name}
                sx={{ marginRight: "80px" }}
                {...other}
              />
            ))}
          </RadioGroup>
        </>
      )}
    />
  );
}

import PropTypes from "prop-types";
// import { useTranslation } from "react-i18next";
// import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
// import "react-phone-number-input/style.css";
import { styled } from "@mui/material/styles";
// form
import { useFormContext, Controller } from "react-hook-form";

// ----------------------------------------------------------------------

RHFPhoneNumberInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
};
const PhoneInputStyle = styled("PhoneInput")(({ theme }) => ({
  height: 56,
  border: `1px solid ${theme.palette.grey[1000]}`,
  borderRadius: "8px",
  padding: "0 14px",
  "&:hover": {
    borderColor: theme.palette.common.black,
  },
  "& input": {
    borderWidth: 0,
    outline: "none",
    paddingLeft: 10,
    fontSize: "1rem",
  },
  "& .PhoneInputCountrySelectArrow": {
    display: "none",
  },
  "& select": {
    display: "none",
  },
  "& + p": {
    color: "#FF4842",
    fontSize: "0.75rem",
    fontWeight: 400,
    textAlign: "left",
    marginTop: "3px !important",
    marginLeft: "15px !important",
  },
}));

export default function RHFPhoneNumberInput({ name, label }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        // validate: (value) => isValidPhoneNumber(value),
      }}
      render={({ field, fieldState: { error } }) => (
        <>
          <PhoneInputStyle
            {...field}
            country="IN"
            placeholder={label}
            countries={["IN"]}
            international={false}
            countryCallingCodeEditable={false}
            defaultCountry="IN"
          />
          {!!error && error?.message && <p>{error?.message}</p>}
        </>
      )}
    />
  );
}

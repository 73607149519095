import { constants } from "../../constants.value";
import { httpService } from "../../helper";

export function getAllQm(id) {
    return httpService()
        .get(`${constants.API.QM}/${id}`)
        .then((response) => response.data)
        .catch((error) => error.response);
};

export function createQm(data) {
    return httpService()
        .post(`${constants.API.QM}`, data)
        .then((response) => response.data)
        .catch((error) => error.response);
};

export function updateQm(id,data) {
    return httpService()
        .put(`${constants.API.QM}/${id}`,data)
        .then((response) => response.data)
        .catch((error) => error.response);
};

import { styled } from '@mui/material/styles';
import { Box, Divider } from '@mui/material';

export const SalesProcess = styled(Box)(() => ({
  width: 'calc(100% - 6%)',
  margin: 'auto',
}));

export const HorizontalRuler = styled(Divider)(() => ({
  margin: '15px 1px',
}));

export const EmptySalesProcess = styled(Box)(() => ({
  '& div': {
    marginBottom: '7px',
  },
}));

export const SalesProcessData = styled(Box)(({ theme }) => ({
  position: 'relative',
  '& .stageOverflow': { flexWrap: 'nowrap', overflowX: 'hidden', paddingLeft:'18px' },
  '& .stageContainer': {
    fontSize: '16px',
    [theme.breakpoints.down('lg')]: {
      width: '151px',
      fontSize: '14px',
    },
    [theme.breakpoints.down('md')]: {
      width: '105px',
      fontSize: '12px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '90px',
      fontSize: '10px',
    },
  },
  '& .stageName.MuiBox-root': {
    fontWeight: 500,
    height: '44px',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '10px',
    clipPath: 'polygon(0% 0, 88% 0, 100% 50%, 88% 100%, 0% 100%)',
    '& span': {
      width: '45%',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textAlign: 'center',
    },
    '& .deleteIcon': {
      '& .MuiAvatar-root': {
        opacity: 0,
        width: '0px',
        height: '0px',
      },
    },
    '&:hover': {
      cursor: 'pointer',
      justifyContent: 'space-evenly',
      '& .showTooltip': {
        paddingLeft: '12px',
      },
      '& .deleteIcon': {
        '& .MuiAvatar-root': {
          backgroundColor: theme.palette.primary.red,
          opacity: 1,
          width: '27px',
          height: '29px',
          [theme.breakpoints.down('lg')]: {
            width: '25px',
            height: '27px',
          },
          [theme.breakpoints.down('md')]: {
            width: '23px',
            height: '25px',
          },
          [theme.breakpoints.down('sm')]: {
            width: '21px',
            height: '22px',
          },
          '&:hover': {
            boxShadow:
              '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
          },
          '& .MuiSvgIcon-root': {
            width: '30px',
            [theme.breakpoints.down('lg')]: {
              width: '27px',
            },
            [theme.breakpoints.down('md')]: {
              width: '20px',
            },
            [theme.breakpoints.down('sm')]: {
              width: '17px',
            },
          },
        },
      },
    },
    '&.firstStageName.MuiBox-root': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      borderTopLeftRadius: '30px',
      borderBottomLeftRadius: '30px',
    },
    '&.stageTrain.MuiBox-root': {
      backgroundColor: theme.palette.primary.fogGray,
      color: theme.palette.text.primary,
      clipPath: 'polygon(88% 0, 100% 50%, 88% 100%, 0% 100%, 10% 50%, 0% 0%)',
    },
  },
  '& .qmContainer.MuiBox-root': {
    border: `1px solid ${theme.palette.border.light}`,
    borderRadius: '4px',
    width: '100%',
    boxSizing: 'border-box',
    margin: '0px 0px 10px 0px',
  },
  '& .addCheckpointButton': {
    marginTop: '6px',
    fontWeight: 500,
    letterSpacing: '1.25px',
    display: 'flex',
    justifyContent: 'flex-start',
    color: theme.palette.button.lightGrey,
  },
  '& .addStageIcon': {
    marginLeft: '5px',
    cursor: 'pointer',
    backgroundColor: theme.palette.common.white,
    color: `${theme.palette.button.outline} !important`,
    height: 'fit-content',
    '& .MuiAvatar-root': {
      transition: '0.2s ease-out',
      opacity: 0,
      width: '0px',
      margin: '0px',
      background: 'transparent',
      color: theme.palette.button.outline,
      border: `2px solid ${theme.palette.button.outline}`,
      '& .MuiSvgIcon-root': {
        color: theme.palette.primary.themeColor,
      },
    },
    '&:hover': {
      '& .MuiAvatar-root': {
        border: `2px solid ${theme.palette.primary.themeColor}`,
        opacity: 1,
        width: '44px',
        height: '44px',
        transition: '0.3s ease-in',
      },
    },
  },
  '& .createStageButton': {
    marginLeft: '30px',
    '& .MuiButton-root': {
      letterSpacing: '1.25px',
      minWidth: '211px',
    },
  },
}));

export const ArrowIconStyle = styled(Box)(({ theme }) => ({
  position: 'absolute',
  cursor: 'pointer',
  zIndex: '99',
  transform: 'translate(-50%, -50%)',
  width: '44px',
  height: '44px',
  background: theme.palette.common.white,
  borderRadius: '4px',
  border: `2px solid ${theme.palette.button.outline}`,
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  top: '22px',
  '&.leftArrowIcon': {
    left: '55px',
  },
  '&.rightArrowIcon': {
    right: '22px',
  },
  '& .arrowIcon': {
    color: theme.palette.primary.themeColor,
    fontWeight: 400,
    fontSize: '18px',
    width: '28px',
    height: '28px',
    lineHeight: '100%',
  },
}));

export const QmHeading = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  margin: '10px 12px',
  '& .qmAbbreviation': {
    width: '45px',
    height: '35px',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: 500,
    justifyContent: 'center',
    borderRadius: '4px 0px 0px 4px',
    color: theme.palette.common.white,
  },
  '& .qmName': {
    width: '65%',
    height: '35px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${theme.palette.border.light}`,
    borderRadius: '0px 4px 4px 0px',
    fontWeight: 500,
    fontSize: '14px',
    color: theme.palette.text.primary,
    '& span': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      margin: '0px',
      padding: '0px 7px',
    },
  },
}));

export const QmCheckpoints = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  cursor: 'pointer',
  padding: '5px 19px 5px 14px',
  '& .checkbox': {
    alignSelf: 'flex-start',
    padding: '0px',
    paddingRight: '9px',
    color: `${theme.palette.primary.whiteSmoke} !important`,
  },
  '& .checkPoint': {
    color: theme.palette.primary.silver,
    fontWeight: 400,
    width: '70%',
    wordBreak: 'break-word',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.5px',
  },
  '& .deleteIcon': {
    position: 'absolute',
    top: '2px',
    right: '0px',
    margin: '2px 5px',
    '& .MuiAvatar-root': {
      align: 'center',
      opacity: 0,
      width: '0px',
      height: '0px',
      cursor: 'pointer',
    },
  },
  '&:hover': {
    backgroundColor: theme.palette.primary.fogGray,
    '& .deleteIcon': {
      display: 'flex',
      alignItems: 'center',
      '& .MuiAvatar-root': {
        backgroundColor: theme.palette.primary.red,
        opacity: 1,
        width: '25px',
        height: '27px',
        [theme.breakpoints.down('lg')]: {
          width: '20px',
          height: '22px',
        },
        [theme.breakpoints.down('md')]: {
          width: '19px',
          height: '21px',
        },
        [theme.breakpoints.down('sm')]: {
          width: '17px',
          height: '19px',
        },
        '&:hover': {
          boxShadow:
            '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
        },
        '& .MuiSvgIcon-root': {
          [theme.breakpoints.down('lg')]: {
            width: '20px',
          },
          [theme.breakpoints.down('md')]: {
            width: '18px',
          },
          [theme.breakpoints.down('sm')]: {
            width: '15px',
          },
        },
      },
    },
    '&::before': {
      fontFamily: 'Material Icons',
      content: '"drag_indicator"',
      position: 'absolute',
      left: '-20px',
      top: '1%',
      zIndex: 1,
      padding: '4px 2px',
      width: '21px',
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.fogGray,
      height: '31px',
      borderRadius: '4px 0px 0px 4px',
    },
  },
}));

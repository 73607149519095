import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { forwardRef } from 'react';

// ----------------------------------------------------------------------

const Page = forwardRef(({ title = '', component: ChildComponent, meta, ...props }, ref) => (
  <>
    <Helmet>
      <title>{`${title} | sales-builder`}</title>
      {meta}
    </Helmet>

    <ChildComponent ref={ref} {...props} />
  </>
));

Page.propTypes = {
  component: PropTypes.func.isRequired,
  title: PropTypes.string,
  meta: PropTypes.node,
};

export default Page;

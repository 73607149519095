import PropTypes from "prop-types";
// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import { styled } from '@mui/material/styles';
import { TextField } from "@mui/material";
// ----------------------------------------------------------------------

const StyledTextField = styled(TextField)(() => ({
  '& label.Mui-focused': {
    color: '#FCB95B'
  },
}));

RHFTextField.propTypes = {
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
  label: PropTypes.string,
  onBlur: PropTypes.func,
};

export default function RHFTextField({ name, label, handleChange, onBlur, ...other }) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name || ''}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <StyledTextField
          {...field}
          onBlur={onBlur || field.onBlur}
          onChange={(...d) => {
            field.onChange(...d);
            if (handleChange && typeof handleChange === "function") {
              handleChange(...d);
            }
          }}
          label={label}
          size="small"
          fullWidth
          value={
            typeof field.value === "number" && field.value === 0
              ? ""
              : field.value || ''
          }
          error={!!error}
          helperText={error?.message}
          {...other}
        />
      )}
    />
  );
};
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ButtonAtom from '../components/Button/buttonAtom';
import CreateSales from '../components/Modals/createSales';
import Header from '../components/Header/Header';

const Wrapper = styled(Box)(() => ({
  width: 'calc(100% - 6%)',
  margin: 'auto ',
}))

const CustomMainCompo = styled('div')(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  display: "flex",
  flexDirection: "column",
  placeItems: "center",
  '& .iconImage': {
    width: "calc(100% - 30%)",
    marginBottom: '48px',
  },
  '& .typoBottom': {
    marginBottom: "29px",
    lineHeight: "20px",
    display: "block",
    fontSize: "20px",
    letterSpacing: "0.25px",
    textAlign: "center",
    whiteSpace: 'nowrap'
  },
}));

export default function LandingImage() {

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  }
  return (
    <>
      <Wrapper>
        <Header />
        <CustomMainCompo>
          <img src="/static/images/main_top.png" alt="" className="iconImage" />
          <Typography className="typoBottom">Click on the button below to create your first sales process</Typography>
          <div className="buttonAtom">
            <ButtonAtom buttonType='submit' ButtonName="CREATE NEW SALES PROCESS" variant="outlined" startIcon={<AddIcon />} onClick={handleOpen} />
          </div>
        </CustomMainCompo>
        {open && <CreateSales open={open} setOpen={setOpen} />}
      </Wrapper>
    </>
  );
}


import { useState, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import SearchIcon from '@mui/icons-material/Search';
import {
  InputAdornment,
  Box,
  styled,
  Typography,
  Divider,
  TablePagination,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  TableHead,
  Avatar,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useAtom } from 'jotai';
import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import ButtonAtom from '../../components/Button/buttonAtom';
import Header from '../../components/Header/Header';
import Clone from '../../components/Modals/Clone';
import { constants } from '../../constants.value';
import CreateSales from '../../components/Modals/createSales';
import Delete from '../../components/Modals/Delete';
import { getSalesProcessListData, deleteSalesProcessRecord } from './salesprocess.service';
import { RHFTextField, FormProvider } from '../../components/hook-form';
import CustomToolTip from '../../components/Tooltip/CustomToolTip';
import { salesProcess } from '../../store';

const Wrapper = styled(Box)(() => ({
  width: 'calc(100% - 9%)',
  margin: 'auto',
  maxWidth: '1140px',
}));

const SearchSalesButton = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  '& .searchIcon': {
    fill: theme.palette.primary.darkerGrey,
    width: 20,
    height: 20,
  },
  '& .textField': {
    width: '420px',
    [theme.breakpoints.down('md')]: {
      width: '340px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '240px',
    },
  },
  '& .buttonAtom': {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    '& .MuiButtonBase-root': {
      [theme.breakpoints.down('md')]: {
        fontSize: '10px !important',
        whiteSpace: 'nowrap',
        padding: '5px 14px',
        width: '220px',
      },
      [theme.breakpoints.down('sm')]: {
        width: 'auto',
        fontSize: '0 !important',
        borderRadius: 25,
        display: 'none',
      },
      '& .MuiButton-startIcon': {
        [theme.breakpoints.down('sm')]: {
          marginLeft: '1px',
          marginRight: '0px',
        },
        '& .MuiSvgIcon-root': {
          [theme.breakpoints.down('sm')]: {
            fontSize: '22px !important',
            marginLeft: '22px',
          },
        },
      },
    },
  },
  '& .avatarVisible': {
    display: 'none',
    '& .MuiAvatar-root': {
      width: '50px',
      height: '50px',
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.contrastText,
    },
    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.palette.primary.contrastText,
      display: 'block !important',
      cursor: 'pointer',
    },
  },
}));

const TableList = styled(Box)(({ theme }) => ({
  maxWidth: '100%',
  display: 'block',
  minWidth: '100%',
  boxShadow: theme.customShadows.switch,
  borderRadius: '4px',
  '& .MuiTableHead-root': {
    '& .MuiTableRow-root': {
      position: 'sticky',
      top: '0',
      left: '0',
      background: theme.palette.primary.contrastText,
      zIndex: 99,
      '& .MuiTableCell-root': {
        color: theme.palette.primary.grey,
        fontWeight: 700,
        fontSize: '12px',
        lineHeight: '20px',
        fontStyle: 'normal',
        letterSpacing: '0.4px',
        padding: '12px !important',
      },
    },
  },
  '& .avatarImage': {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '100%',
    width: '32px',
    height: '32px',
    cursor: 'pointer',
    gap: '4px',
    color: theme.palette.primary.dark,
    '&.avatarImageA': {
      background: theme.palette.button.lightGreen,
    },
  },
  '& .avatarImageBlackContent': {
    background: theme.palette.primary.themeColor,
    width: '32px',
    height: '32px',
    cursor: 'pointer',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '100%',
    color: theme.palette.primary.contrastText,
  },
  '& .filterIconSize': {
    width: '18px',
    height: '18px',
  },
  '& .pagination': {
    [theme.breakpoints.down('sm')]: {
      position: 'sticky',
    },
    '& .MuiTablePagination-selectLabel': {
      [theme.breakpoints.down('sm')]: {
        letterSpacing: '-0.75px',
      },
      [theme.breakpoints.up('xs')]: {
        letterSpacing: '-0.125px',
      },
    },
    '& .MuiTablePagination-select': {
      boxShadow: theme.customShadows.switch,
    },
    '& .MuiTablePagination-actions': {
      '& .MuiButtonBase-root': {
        padding: 0,
      },
    },
  },
  '& .tableContainer': {
    maxHeight: 'calc(100vh - 210px)',
    overflowX: 'hidden !important',
    [theme.breakpoints.up('lg')]: {
      maxHeight: 'calc(100vh - 235px)',
      overflowX: 'hidden !important',
    },
    [theme.breakpoints.down('md')]: {
      overflowX: 'auto !important',
      scrollBehavior: 'smooth',
    },
    '&::-webkit-scrollbar': {
      width: '4px',
      height: '5px',
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: 10,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 10,
      background: `linear-gradient(to top, ${theme.palette.primary.greyLightShade} calc(100% - 44px), ${theme.palette.primary.contrastText} 44px)`,
    },
    '& .tableCell': {
      '&.cellName': {
        minWidth: 170,
      },
      '&.cellCreatedBy': {
        minWidth: 230,
      },
      '&.cellLastUpdated': {
        minWidth: 230,
      },
      '&.cellSharedWith': {
        minWidth: 170,
      },
      '&.columnSort': {
        color: theme.palette.button.lightBlack,
      },
    },
    '& .filterIcon': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      cursor: 'pointer',
    },
  },
}));

const HorizontalRuler = styled(Divider)(({ theme }) => ({
  marginTop: '15px',
  marginBottom: '15px',
  marginLeft: '1px',
  marginRight: '1px',
  [theme.breakpoints.up('lg')]: {
    marginTop: '12px',
    marginBottom: '12px',
  },
}));

const CustomTableCell = styled(TableCell)(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '20px',
  color: theme.palette.button.lightBlack,
  letterSpacing: '0.25px',
  '& .nameIconOnHover': {
    display: 'flex',
    gap: '10px',
    placeItems: 'center',
  },
  '& .Invited': {
    fontStyle: 'normal',
    border: `2px solid ${theme.palette.primary.themeColor}`,
    borderRadius: '18px',
    padding: '2px 12px',
    fontSize: '12px',
    lineHeight: '20px',
    color: theme.palette.primary.dark,
  },
  '& .avatarClone': {
    width: 32,
    height: 32,
    cursor: 'pointer',
    visibility: 'hidden',
    background: theme.palette.primary.themeColor,
    zIndex: 1,
    '&:hover': {
      boxShadow: theme.customShadows.switch,
    },
  },
  '& .avatarDelete': {
    width: 32,
    height: 32,
    cursor: 'pointer',
    visibility: 'hidden',
    background: theme.palette.primary.red,
    zIndex: 1,
    '&:hover': {
      boxShadow: theme.customShadows.switch,
    },
  },
}));

const CustomTableRow = styled(TableRow)(({ theme }) => ({
  height: '48px',
  background: theme.palette.primary.contrastText,
  cursor: 'pointer',
  '& .MuiSvgIcon-root': {
    width: '20px',
    height: '20px',
  },
  '& .collabratorsAvatar': {
    '& .collabratorToolTip': {
      display: 'flex',
      gap: '5px',
      paddingTop: '2px',
      paddingBottom: '2px',
      '& .collabratorColors': {
        height: '32px',
        width: '32px',
        fontSize: '14px',
        color: theme.palette.primary.dark,
        fontWeight: '500',
        '&.moreCollabrator': {
          color: theme.palette.primary.contrastText,
          backgroundColor: theme.palette.primary.themeColor,
        },
      },
    },
  },
  '&:hover': {
    background: theme.palette.primary.greyShade,
    '& .MuiAvatar-root': {
      display: 'flex',
      visibility: 'visible',
    },
    '& .Invited': {
      backgroundColor: theme.palette.primary.contrastText,
    },
  },
  '& .MuiTableCell-root': {
    borderRight: `solid 2px ${theme.palette.grey[100]}`,
    paddingTop: '7px',
    paddingBottom: '7px',
    whiteSpace: 'nowrap',
  },
}));

const CustomTableNoData = styled(TableCell)(() => ({
  textAlign: 'center',
  '& .noDataImage': {
    width: '105px',
    height: '83',
    left: '50%',
    top: '50%',
    margin: 'auto',
    marginBottom: '20px',
  },
  '& .dataContent': {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '20px',
    alignItems: 'center',
    letterSpacing: '0.25px',
  },
}));

export default function SalesProcessList() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [on, setOn] = useState(false);
  const [salesData, setSalesData] = useState({});
  const [openCreateSalesModal, setOpenCreateSalesModal] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [salesProcessData, setSalesProcessData] = useAtom(salesProcess);
  const [search, setSearch] = useState('');
  const [noDataFound, setNoDataFound] = useState(false);
  const [orderData, setOrderData] = useState(['updatedAt', 'DESC']);
  const { RESPONSE_STATUS } = constants;
  const navigate = useNavigate();

  const getData = async () => {
    const response = await getSalesProcessListData({
      limit: rowsPerPage,
      offset: page,
      orderSort: JSON.stringify(orderData),
      searchValue: search || '',
    });
    if (response?.status === RESPONSE_STATUS.SUCCESS_MESSAGE && response.data.length) {
      setSalesProcessData(response.data);
      setNoDataFound(false);
      setCount(response.count || 0);
    } else {
      setNoDataFound(true);
      setCount(0);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleOpen = () => {
    setOn(true);
  };

  const handleCreateSalesOpen = () => {
    setOpenCreateSalesModal(true);
  };

  const handleDeleteOpen = (data) => {
    setSalesData(data);
    setDeleteOpen(true);
  };

  const deleteSalesProcess = async () => {
    const response = await deleteSalesProcessRecord(salesData?.salesProcessId);
    if (response?.code === RESPONSE_STATUS.SUCCESS) {
      getData();
      setDeleteOpen(false);
    }
  };

  const defaultValues = {
    salesName: '',
  };
  const methods = useForm({
    defaultValues,
  });

  const handleClick = (e, path) => {
    e.stopPropagation();
    navigate(path);
  };

  const toolTipColors = ['#75ee0a4f', '#06db634d', '#6e94854d', '#fd9daf4d'];

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 1000);
    };
  };

  const handleSearch = (e) => {
    if (e.target.value) {
      setPage(0);
      setSearch(e.target.value);
    } else {
      setPage(0);
      setSearch('');
    }
  };

  const optimisedSearch = useCallback(debounce(handleSearch), []);

  const customSort = (id, name) => {
    document.getElementById('nameId').classList.remove('columnSort');
    document.getElementById('updatedId').classList.remove('columnSort');
    document.getElementById('createdId').classList.remove('columnSort');
      if (orderData.includes(name) && orderData.includes('ASC')) {
      
        document.getElementById(id).classList.add('columnSort');
        setOrderData([name, 'DESC']);
      } else {
        document.getElementById(id).classList.add('columnSort');
        setOrderData([name, 'ASC']);
      }
    
  }
  useEffect(() => {
    getData();
  }, [rowsPerPage, page, search, orderData]);

  return (
    <>
      <Header />
      <Wrapper>
        <SearchSalesButton>
          <FormProvider methods={methods}>
            <RHFTextField
              id="search"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon className="searchIcon" />
                  </InputAdornment>
                ),
              }}
              handleChange={optimisedSearch}
              variant="outlined"
              className="textField"
              label="Search"
              name="search"
            />
          </FormProvider>

          <div className="buttonAtom">
            <ButtonAtom
              ButtonName="Create New Sales Process"
              buttonType="submit"
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={handleCreateSalesOpen}
            />
          </div>
          <div className="avatarVisible">
            <Avatar sx={{ backgroundColor: '#fcb95b' }}>
              <AddIcon />
            </Avatar>
          </div>
        </SearchSalesButton>
        <HorizontalRuler />
        <TableList>
          <TableContainer className="tableContainer">
            <Table aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell
                    className="tableCell cellName"
                    id="nameId"
                    onClick={() => customSort('nameId','salesProcessName')}
                  >
                    <div className="filterIcon">
                      Name
                      {orderData.includes('ASC') && orderData.includes('salesProcessName') ? (
                        <ArrowDownwardIcon className="filterIconSize" />
                      ) : (
                        <ArrowUpwardIcon className="filterIconSize" />
                      )}
                    </div>
                  </TableCell>
                  <TableCell
                    className="tableCell cellCreatedBy"
                    id="createdId"
                    onClick={() => customSort('createdId','firstName')}
                  >
                    <div className="filterIcon">
                      Created By
                      {orderData.includes('ASC') && orderData.includes('firstName') ? (
                        <ArrowDownwardIcon className="filterIconSize" />
                      ) : (
                        <ArrowUpwardIcon className="filterIconSize" />
                      )}
                    </div>
                  </TableCell>
                  <TableCell
                    className="tableCell cellLastUpdated"
                    id="updatedId"
                    onClick={() => customSort('updatedId','updatedAt')}
                  >
                    <div className="filterIcon">
                      Last Updated
                      {orderData.includes('ASC') && orderData.includes('updatedAt') ? (
                        <ArrowDownwardIcon className="filterIconSize" />
                      ) : (
                        <ArrowUpwardIcon className="filterIconSize" />
                      )}
                    </div>
                  </TableCell>
                  <TableCell className="tableCell cellSharedWith">Shared With</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!noDataFound ? (
                  salesProcessData?.length &&
                  salesProcessData.map((row) => (
                    <CustomTableRow
                      onClick={(e) =>
                        handleClick(e, `/sales-process-board/${row.salesProcessId}`)
                      }
                      key={row.id}
                    >
                      <CustomTableCell>
                        <div className="nameIconOnHover">
                          {row.sales_process?.salesProcessName}
                          {row.isOwner === true ? (
                            <>
                              <Avatar
                                className="avatarClone"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleOpen();
                                }}
                              >
                                <FileCopyIcon />
                              </Avatar>
                              <Avatar
                                className="avatarDelete"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDeleteOpen(row);
                                }}
                              >
                                <DeleteIcon />
                              </Avatar>
                            </>
                          ) : (
                            <Typography className="Invited">invited</Typography>
                          )}
                        </div>
                      </CustomTableCell>
                      <TableCell align="left">{row.sales_process?.user.firstName}</TableCell>
                      <TableCell align="left">
                        {moment(row.sales_process?.updatedAt || row.sales_process?.updated_at).format('D MMMM YYYY')}
                      </TableCell>
                      <TableCell className="collabratorsAvatar">
                        <div className="collabratorToolTip">
                          {row.sales_process.collaborators.length > 1 &&
                            row.sales_process.collaborators.map(
                              (shareUser, _index) =>
                                (_index <=4 &&
                                row.userId !== shareUser.userId) && (
                                  <CustomToolTip
                                    placement="top"
                                    key={_index}
                                    name={shareUser.user.firstName}
                                    access={shareUser.canEdit ? 'can edit' : 'can view'}
                                  >
                                    <Avatar className="collabratorColors" sx={{ background: toolTipColors[_index] }}>
                                      {shareUser.user.firstName?.toUpperCase().slice(0, 1)}
                                    </Avatar>
                                  </CustomToolTip>
                                )
                            )}
                          {row.sales_process.collaborators.length > 5 && (
                            <>
                              <CustomToolTip
                                placement="top"
                                name="name"
                                isTooptip
                                id={row.userId}
                                TooltipArray={row.sales_process?.collaborators.slice(5)}
                                // TooltipArray={salesProcessData}
                              >
                                <Avatar className="collabratorColors moreCollabrator">
                                  {`${row.sales_process.collaborators.slice(5).length}+`}
                                </Avatar>
                              </CustomToolTip>
                            </>
                          )}
                        </div>
                      </TableCell>
                    </CustomTableRow>
                  ))
                ) : (
                  <TableRow>
                    <CustomTableNoData colSpan="4">
                      <img src="/static/images/Group 181.png" alt="No Data" className="noDataImage" />
                      <Typography className="dataContent">There is no such data in the table</Typography>
                    </CustomTableNoData>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            className="pagination"
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableList>
        {on && <Clone on={on} setOn={setOn} />}
        {openCreateSalesModal && (
          <CreateSales open={openCreateSalesModal} setOpen={setOpenCreateSalesModal} getData={getData} />
        )}
        {deleteOpen && <Delete deleteOpen={deleteOpen} setDeleteOpen={setDeleteOpen} onClick={deleteSalesProcess} name="sales-process" title={salesData?.sales_process?.salesProcessName}/>}
      </Wrapper>
    </>
  );
}

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
const {
    REACT_APP_PROJECT_REGION,
    REACT_APP_COGNITO_POOL_ID,
    REACT_APP_USER_POOL_ID,
    REACT_APP_WEB_CLIENT_ID } = process.env;
const awsmobile = {
    "aws_project_region": REACT_APP_PROJECT_REGION,
    "aws_cognito_region": REACT_APP_PROJECT_REGION,
    "aws_user_pools_id": REACT_APP_USER_POOL_ID,
    "aws_user_pools_web_client_id": REACT_APP_WEB_CLIENT_ID,
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;

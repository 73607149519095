import { useState, useEffect } from 'react';
import { Grid, Typography, Box, DialogContent, DialogActions, Divider, TextField } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { PropTypes } from 'prop-types';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AddIcon from '@mui/icons-material/Add';
import { useParams } from 'react-router-dom';
import ButtonAtom from '../Button/buttonAtom';
import ModalCustom from '../Dialog/modalCustom';
import { FormProvider, RHFCheckbox, RHFRadio, RHFSelectbox, RHFTextField, RHFSwitch } from '../hook-form';
import { RHFAutocompleteSingle } from '../hook-form/RHFAutocomplete';
import ModalButtonAtom from '../Button/modal-button';
import { constants } from '../../constants.value';
import { createCheckpoint, editCheckpointData } from './createCheckpoint.service';

const LightTooltip = styled(Tooltip)(() => ({
  backgroundColor: 'transparent',
}));

const CustomCheckpoints = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItem: 'baseline',
  justifyContent: 'flex-start',
  '& .rightCheckpoints': {
    marginLeft: '22px',
    height: '100%',
    '& .viewText': {
      padding: '8px 0px !important',
      color: theme.palette.primary.main,
      textAlign: 'left',
    },
    '& .watchVideo': {
      padding: '8px 0px !important',
      color: theme.palette.primary.main,
      textAlign: 'left',
    },
  },
  '& .formCheckpoints': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    minWidth: '50%',
    '& .dividerLine': {
      marginTop: '20px',
    },
    '& .picklist': {
      display: 'flex',
      justifyContent: 'space-between',
      height: '50px',
      overflow: 'auto',
      marginTop: '-13px',
      '& .picklist-container': {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '5px',
        '& .picklist-value': {
          display: 'flex',
          alignItems: 'center',
          flexBasis: 35,
          flexShrink: 0,
          '& .picklistTypo': {
            cursor: 'pointer',
          },
        },
      },
      '& .MuiFormGroup-root': {
        '& .MuiFormControlLabel-root': {
          marginRight: '2px',
          '& .MuiButtonBase-root': {
            padding: '5px !important',
            color: `${theme.palette.primary.main}`,
            '& .MuiSvgIcon-root': {
              fontSize: '1.2rem',
            },
          },
        },
      },
      '& .addPicklist': {
        '& .addPicklistIcon': {
          color: theme.palette.button.lightGrey,
          fontSize: '11px',
        },
      },
    },
    '& .autoComplete': {
      '& .MuiFormControl-root': {
        '& .MuiInputBase-root': {
          '& .MuiInputBase-input': {
            padding: '6.5px 4px 2.5px 6px',
          },
        },
      },
    },
    '&> .MuiBox-root': {
      width: 'calc(100% - 20px)',
      margin: '12px 0px',
      '&.checkbox': {
        margin: '12px 0px 0px 0px',
        '& .MuiFormControl-root': {
          marginTop: '8px'
        }
      },
      '& .MuiTypography-root': {
        textAlign: 'left',
        fontSize: '14px',
        fontWeight: 400,
        color: theme.palette.button.lightGrey,
        padding: 0,
      },
      '& .MuiFormControl-root': {
        '& .MuiFormLabel-root': {
          fontSize: '16px',
          color: theme.palette.primary.darkerGrey,
          overflow: 'visible',
          lineHeight: 'inherit',
        },
      },
    },
    '& .checkboxTitle': {
      marginLeft: '20px',
      display: 'flex',
      placeItems: 'center',
      '& .MuiFormControlLabel-root': {
        '& .MuiButtonBase-root': {
          padding: 0,
        },
        '& .MuiTypography-root': {
          textAlign: 'left',
          fontSize: '16px',
          fontWeight: 400,
          color: theme.palette.primary.silver,
          paddingLeft: '6px',
          marginLeft: '4px',
        },
        '& .Mui-checked': {
          color: theme.palette.primary.themeColor,
        },
      },
      '& .tooltipIcon': {
        color: theme.palette.primary.main,
        fontSize: '18px',
        position: 'relative',
        left: '-6px',
      },
    },
    '& .qmHelpText': {
      marginTop: '24px',
      '& .MuiOutlinedInput-root': {
        padding: '0px'
      },
      '& .instructions': {
        display: 'flex',
        placeItems: 'center',
        fontSize: '14px',
        color: theme.palette.primary.main,
        letterSpacing: '0.1px',
        marginTop: '4px',
      },
      '& .furtherTooltipIcon': {
        color: theme.palette.primary.main,
        fontSize: '18px',
        marginLeft: '10px',
        position: 'relative',
        bottom: '2px',
      },
      '& .MuiFormControl-root': {
        '& .MuiFormLabel-root': {
          lineHeight: '0.8em',
        },
      },
    },
  },
}));

const CustomTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'transparent',
      padding: '0px',
      width: '60%',
      '& p': {
        backgroundColor: theme.palette.primary.main,
        fontSize: 11,
        fontWeight: '300',
        textAlign: 'center',
        width: '100%',
        borderRadius: '4px',
        whiteSpace: 'nowrap',
      },
    },
  })
);

const CreateCheckPoint = (props) => {
  const { RESPONSE_STATUS } = constants;
  const { id: salesProcessId } = useParams();
  const { openCheckpoint, setOpenCheckpoint, getCheckpoint, stageColumns, qmCheckpointData, checkpointObj } = props;
  const { checkpointType, defaultQM, defaultChecklistOptions, stageId, id: checkpointId, position } = checkpointObj
  const [radioItem, setRadioItem] = useState({});
  const [radioData, setRadioData] = useState({ radio0: 'input0' });
  const [checkBoxItem, setCheckBoxItem] = useState({});
  const [checkBoxData, setCheckBoxData] = useState({ checkbox0: 'input0' });

  const types = [
    { name: 'Text Box', value: 'Text Box', icon: '/static/images/edit.svg' },
    { name: 'Single Picklist', value: 'Single Picklist', icon: '/static/images/multi_select_checkbox.svg' },
    { name: 'Multiple Checklist', value: 'Multiple Checklist', icon: '/static/images/multi_select_checkbox.svg' },
    { name: 'Datepicker', value: 'Datepicker', icon: '/static/images/date_time.svg' },
    { name: 'Contact Tagging', value: 'Contact Tagging', icon: '/static/images/add_contact.svg' },
  ];
  const scores = [
    { name: 1, value: '1' },
    { name: 2, value: '2' },
    { name: 3, value: '3' },
    { name: 4, value: '4' },
    { name: 5, value: '5' },
  ];
  const order = [
    { name: 1, value: '1' },
    { name: 2, value: '2' },
    { name: 3, value: '3' },
    { name: 4, value: '4' },
  ];

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Checkpoint Title is Required'),
    checkpointType: Yup.string().required('Checkpoint Type is Required').nullable(),
    defaultQM: Yup.object().required('Default QM is Required').nullable(),
    stage: Yup.object().required('Stage is Required').nullable(),
  });

  const defaultValues = {
    title: '',
    checkpointType: '',
    minCount: '',
    maxCount: '',
    defaultQM: '',
    helpText: '',
    detailsRequired: false,
    gate: false,
    hardGate: false,
    closePlanVisibility: false,
    score: '',
    planVisibilityOrder: '',
    contactRole: '',
    stage: '',
  };

  const onFormSubmit = async (data) => {
    if (!!data && Object.keys(data).length) {
      const defaultCheckList =
        data.checkpointType === 'Text Box' && [data.minCount, data.maxCount] ||
        data.checkpointType === 'Single Picklist' && Object.values(radioData) ||
        data.checkpointType === 'Multiple Checklist' && Object.values(checkBoxData) ||
        data.checkpointType === 'Contact Tagging' && [data.contactRole];
      const payload = {
        title: data.title,
        checkpointType: data.checkpointType,
        defaultChecklistOptions: defaultCheckList || [],
        helpText: data.helpText,
        salesProcessId,
        stageId: data?.stage.id,
        score: Number(data.score),
        gate: data.gate,
        hardGate: data.hardGate,
        detailsRequired: data.detailsRequired,
        closePlanVisibility: data.closePlanVisibility,
        planVisibilityOrder: Number(data.planVisibilityOrder),
        defaultQM: data?.defaultQM.id,
        contactRole: data.contactRole || '',
        position
        // checkpointOrder: checkpointOrder || null,
      };
      if (checkpointId) {
        const response = await editCheckpointData(checkpointId, payload);
        if (response.data.status === RESPONSE_STATUS.SUCCESS_MESSAGE) {
          onClose();
          getCheckpoint();
        } else {
          setError('title', { message: response.message }, { type: 'focus' }, { shouldFocus: true });
        }
      }
      else {
        const response = await createCheckpoint(payload);
        if (response?.status === RESPONSE_STATUS.SUCCESS_MESSAGE) {
          onClose();
          getCheckpoint();
        } else {
          setError('title', { message: response.message }, { type: 'focus' }, { shouldFocus: true });
        }
      }

    }
  };

  const onClose = () => {
    setOpenCheckpoint((pre) => ({
      ...pre,
      checkpoint: false
    }))
    // setCheckpointOrder(qmCheckpointData?.checkpointData?.length);
    reset();
  };

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: 'all',
  });

  const {
    watch,
    reset,
    handleSubmit,
    setError,
    setValue,
    formState: { isSubmitting, errors },
  } = methods;

  useEffect(() => {
    if (checkpointId) {
      Object.keys(checkpointObj).forEach(ele => setValue(`${ele}`, checkpointObj[ele]))
      if (defaultChecklistOptions.length && (checkpointType === 'Single Picklist' || checkpointType === 'Multiple Checklist' || checkpointType === "Text Box")) {
        defaultChecklistOptions.forEach((element, index) => {
          if (checkpointType === 'Single Picklist') {
            setRadioData((prevState) => ({ ...prevState, [`radio${index}`]: element }));
          } else if (checkpointType === 'Multiple Checklist') {
            setCheckBoxData((prevState) => ({ ...prevState, [`checkbox${index}`]: element }));
          } else if (checkpointType === "Text Box") {
            if (index === 0) {
              setValue('minCount', defaultChecklistOptions[index])
            } else {
              setValue('maxCount', defaultChecklistOptions[index]);
            }
          }
        });
      }
      const checkData = stageColumns.filter(item => item.id === stageId && item)
      const checkDataQm = checkData.length && checkData[0] && Object.keys(checkData[0]).length && checkData[0]?.qm?.filter(item => item.id === defaultQM);
      setValue('stage', ...checkData);
      setValue('defaultQM', ...checkDataQm);
    }
    // else {
    //   setCheckpointOrder(checkpointOrder + 1);
    // }
  }, []);

  const handleChangeRadio = (e) => {
    setRadioData((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  const handleChangeCheckBox = (e) => {
    setCheckBoxData((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  const handleRadioList = (radioIndex) => {
    setRadioItem((prevState) => ({ ...prevState, text: true, index: radioIndex }));
  };

  const handleCheckBoxList = (checkBoxIndex) => {
    setCheckBoxItem((prevState) => ({ ...prevState, text: true, index: checkBoxIndex }));
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      setRadioItem(false);
    }
  };

  const handleKeyPressCheck = (e) => {
    if (e.key === 'Enter') {
      setCheckBoxItem(false);
    }
  };

  const handleRadioItem = () => {
    const { length } = Object.keys(radioData);
    setRadioData((prevState) => ({ ...prevState, [`radio${length}`]: `input${length}` }));
  };

  const handleCheckBoxItem = (length) => {
    setCheckBoxData((prevState) => ({ ...prevState, [`checkbox${length}`]: `input${length}` }));
  };

  const watchCheckpointType = watch('checkpointType');
  const watchClosePlan = watch('closePlanVisibility');
  const watchGate = watch('gate');
  const onErrors = (errors) => console.error(errors);
  
  return (
    <ModalCustom openDialog={openCheckpoint} isMultiGridForm dialogHeading={checkpointId ? "Edit Checkpoint" : "Create Checkpoint"} onClose={onClose}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onFormSubmit, onErrors)}>
        <DialogContent>
          <CustomCheckpoints>
            <Box className="formCheckpoints">
              <Box mb={1}>
                <RHFTextField name="title" label="Checkpoint Title" size="small" />
              </Box>
              <Box>
                <RHFSelectbox name="checkpointType" error={errors.checkpointType?.message} label="Checkpoint Type" menus={types} isCheckpoint />
              </Box>
              {watchCheckpointType === 'Text Box' && (
                <>
                  <Box>
                    <RHFTextField name="minCount" label="Minimum Character count" size="small" type="number" />
                  </Box>
                  <Box>
                    <RHFTextField
                      name="maxCount"
                      label="Maximum character for input field"
                      size="small"
                      type="number"
                    />
                    <Divider className="dividerLine" />
                  </Box>
                </>
              )}
              {watchCheckpointType === 'Contact Tagging' && (
                <Box>
                  <RHFTextField name="contactRole" label="Contact Role" size="small" />
                  <Divider className="dividerLine" />
                </Box>
              )}
              {watchCheckpointType === 'Datepicker' && (
                <Box>
                  <Divider />
                </Box>
              )}
              {watchCheckpointType === 'Single Picklist' && (
                <Box>
                  <div className="picklist">
                    <div className="picklist-container">
                      {Object.keys(radioData)?.length
                        ? Object.keys(radioData).map((item, index) => (
                          <Box key={index} className="picklist-value">
                            <RHFRadio name={`radio${index}`} title="" disabled labels={[{ value: 'radio' }]} />

                            {Object.keys(radioItem).length && radioItem.text && radioItem.index === index ? (
                              <TextField
                                name={`radio${index}`}
                                variant="standard"
                                value={radioData[item]}
                                onKeyPress={handleKeyPress}
                                onChange={(e) => {
                                  handleChangeRadio(e);
                                }}
                              />
                            ) : (
                              <>
                                <Typography
                                  name={`radio${index}`}
                                  className="picklistTypo"
                                  onClick={() => handleRadioList(index)}
                                >
                                  {radioData[item]}
                                </Typography>
                              </>
                            )}
                          </Box>
                        ))
                        : ''}
                    </div>
                    <div className="addPicklist">
                      <ButtonAtom
                        ButtonName="ADD ITEMS"
                        variant="text"
                        buttonType="text"
                        startIcon={<AddIcon />}
                        className="addPicklistIcon"
                        onClick={handleRadioItem}
                      />
                    </div>
                  </div>
                  <Divider />
                </Box>
              )}
              {watchCheckpointType === 'Multiple Checklist' && (
                <Box>
                  <div className="picklist">
                    <div className="picklist-container">
                      {Object.keys(checkBoxData)?.length
                        ? Object.keys(checkBoxData).map((item, index) => (
                          <Box key={index} className="picklist-value">
                            <RHFCheckbox name={`checkbox${index}`} disabled labels={[{ value: 'checkbox' }]} />

                            {Object.keys(checkBoxItem).length && checkBoxItem.text && checkBoxItem.index === index ? (
                              <TextField
                                name={`checkbox${index}`}
                                variant="standard"
                                value={checkBoxData[item]}
                                onKeyPress={handleKeyPressCheck}
                                onChange={(e) => {
                                  handleChangeCheckBox(e);
                                }}
                              />
                            ) : (
                              <>
                                <Typography
                                  name={`checkbox${index}`}
                                  className="picklistTypo"
                                  onClick={() => handleCheckBoxList(index)}
                                >
                                  {checkBoxData[item]}
                                </Typography>
                              </>
                            )}
                          </Box>
                        ))
                        : ''}
                    </div>
                    <div className="addPicklist">
                      <ButtonAtom
                        ButtonName="ADD ITEMS"
                        variant="text"
                        buttonType="text"
                        startIcon={<AddIcon />}
                        className="addPicklistIcon"
                        onClick={() => handleCheckBoxItem(Object.keys(checkBoxData).length)}
                      />
                    </div>
                  </div>
                  <Divider />
                </Box>
              )}
              <Box>
                <RHFAutocompleteSingle name="stage" label="Stage Name" options={stageColumns} />
              </Box>
              <Box>
                <RHFSelectbox name="score" label="Score" menus={scores} />
              </Box>

              <Box>
                <RHFAutocompleteSingle name="defaultQM" label="Default Qualifiers and Milestones" options={qmCheckpointData?.qmData} />
              </Box>

              <Box className='checkbox'>
                <div className="checkboxTitle">
                  <RHFCheckbox
                    name="detailsRequired"
                    label="Details Required"
                    height={24}
                    width={24}
                  />
                  <LightTooltip
                    arrow
                    placement="right"
                    className="lightToolTip"
                    title={
                      <Typography
                        style={{ whiteSpace: 'nowrap', fontSize: '10px', fontWeight: 400 }}
                      >
                        User needs to input items before they can check this point.
                      </Typography>
                    }
                  >
                    <InfoOutlinedIcon className="tooltipIcon" />
                  </LightTooltip>
                </div>
              </Box>

              <Box className='checkbox'>
                <div className="checkboxTitle">
                  <RHFCheckbox name="gate" label="Gate" />
                  <LightTooltip
                    arrow
                    placement="right"
                    title={
                      <Typography
                        style={{ whiteSpace: 'nowrap', fontSize: '10px', fontWeight: 400}}
                      >
                        Stage Exit Criteria.
                      </Typography>
                    }
                  >
                    <InfoOutlinedIcon className="tooltipIcon" />
                  </LightTooltip>
                </div>
                {watchGate && (
                  <Box ml={6}>
                    <RHFSwitch label="Prevent user from getting to next stage" name="hardGate" />
                  </Box>
                )}
              </Box>

              <Box className='checkbox'>
                <div className="checkboxTitle">
                  <RHFCheckbox name="closePlanVisibility" label="Visible on Close Plan" />
                  <CustomTooltip
                    placement="right-end"
                    title={
                      <>
                        <img src="/static/images/closePlan.png" alt="" />
                        <p
                        >Important corner store that user must <br /> accomplish in mutual close plan.</p>
                      </>
                    }
                  >
                    <InfoOutlinedIcon className="tooltipIcon" />
                  </CustomTooltip>
                </div>
                {watchClosePlan && (
                  <Box ml={5} mt={1}>
                    <RHFSelectbox name="planVisibilityOrder" label="Close Plan Order" menus={order} />
                  </Box>
                )}
              </Box>

              <Box className="qmHelpText">
                <RHFTextField name="helpText" label="Help Text" size="large" multiline
                  rows={3}
                  maxRows={Infinity}
                />
                <Typography className="instructions">
                  Further instructions for user to understand CP.
                  <CustomTooltip placement="right-end" title={<img src="/static/images/help.png" alt="" />}>
                    <InfoOutlinedIcon className="furtherTooltipIcon" />
                  </CustomTooltip>
                </Typography>
              </Box>
            </Box>

            <Grid container xs={6} className="rightCheckpoints">
              <Grid item xs={12} >
                <Typography variant="body2" className="viewText">
                  Example of how CP will look like:
                </Typography>
              </Grid>
              <Grid item xs={12} >
                {!watchCheckpointType && <img src="/static/images/textbox.png" alt="default" /> ||
                  watchCheckpointType === 'Text Box' && <img src="/static/images/textbox.png" alt="Type 1" /> ||
                  watchCheckpointType === 'Multiple Checklist' && <img src="/static/images/checkbox.png" alt="Type 3" /> ||
                  watchCheckpointType === 'Single Picklist' && <img src="/static/images/picklist.png" alt="Type 2" /> ||
                  watchCheckpointType === 'Datepicker' && <img src="/static/images/datepicker.png" alt="Type 4" /> ||
                  watchCheckpointType === 'Contact Tagging' && <img src="/static/images/contact.png" alt="Type 5" />}
              </Grid>
              <Grid item container >
                <Grid item xs={12}>
                  <Typography className="watchVideo" variant="body2">
                    Watch the video to see how it works.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <img src="/static/images/tutorialvideo.png" alt="Some video" />
                </Grid>
              </Grid>
            </Grid>
          </CustomCheckpoints>
        </DialogContent>
        <Divider />
        <DialogActions>
          <ModalButtonAtom isSubmitting={isSubmitting} onClose={onClose} buttonSubmitName={checkpointId ? "Save" : "Create"} />
        </DialogActions>
      </FormProvider>
    </ModalCustom >
  );
};

CreateCheckPoint.propTypes = {
  checkpointObj: PropTypes.object,
  openCheckpoint: PropTypes.bool,
  setOpenCheckpoint: PropTypes.func,
  // checkpointOrder: PropTypes.number,
  // setCheckpointOrder: PropTypes.func,
  getCheckpoint: PropTypes.func,
  stageColumns: PropTypes.array,
  qmCheckpointData: PropTypes.object
}

export default CreateCheckPoint;

import { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { toasterService } from '../helper/index';

const Popup = (props) => {
  const { pathname } = useLocation();
  const isResetPassword = pathname === '/reset-password';
  const { profileMenuOpen, setProfileMenuOpen, profileIcon } = props;
  const navigate = useNavigate();
  const email = localStorage.getItem('logined_email');
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
    setProfileMenuOpen(false);
  };

  const MenuComponent = styled(Menu)(() => ({
    '& .MuiPaper-root': {
      borderRadius: '4px',
      marginTop: '10px',
      boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.2), 0px 9px 10px rgba(0, 0, 0, 0.14), 0px 5px 14px rgba(0, 0, 0, 0.12)',
      '& .MuiList-root': {
        padding: '18px 0px',
      },
    },
  }));

  useEffect(() => {
    if (profileMenuOpen) {
      setAnchorEl(profileIcon);
    }
  }, [profileMenuOpen, profileIcon]);

  const signOut = async () => {
    try {
      await Auth.signOut();
      localStorage.removeItem('satellizer_token');
      navigate('/');
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };
  const onSubmit = async () => {
      const res = await Auth.forgotPassword(email);
      if (Object.keys(res.CodeDeliveryDetails).length) {
        toasterService("Code has been sent successfully");
        navigate('/reset-password')
      }
  };
  return (
    <MenuComponent
      anchorEl={anchorEl}
      open={open}
      disableEscapeKeyDown
      disableAutoFocusItem
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      onClose={handleClose}
    >
      <MenuItem onClick={onSubmit} disabled={isResetPassword}>Change Password</MenuItem>
      <MenuItem onClick={signOut}>Logout</MenuItem>
    </MenuComponent>
  );
};

Popup.propTypes = {
  profileMenuOpen: PropTypes.bool.isRequired,
  setProfileMenuOpen: PropTypes.func,
  profileIcon: PropTypes.object,
};
export default Popup;

import { useState, useEffect, useRef } from 'react';
import { Box, Grid, Avatar } from '@mui/material';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateStage from '../../components/Modals/createStage';
import CreateCheckPoint from '../../components/Modals/CreateCheckPoint';
import Header from '../../components/Header/Header';
import { constants } from '../../constants.value';
import { toasterService } from '../../helper/index';
import Delete from '../../components/Modals/Delete';
import ButtonAtom from '../../components/Button/buttonAtom';
import CustomToolTip from '../../components/Tooltip/CustomToolTip';
import {
  SalesProcess,
  HorizontalRuler,
  EmptySalesProcess,
  SalesProcessData,
  ArrowIconStyle,
} from './SalesProcessBoard.style';
import { getAllQm as getAllQmApi } from '../../components/Modals/createQm.service';
import { getAllCheckpoint } from '../../components/Modals/createCheckpoint.service';
import {
  getStages as getStagesApi,
  deleteStage as deleteStageApi,
  updateCheckpointOrder as updateCheckpointOrderApi,
} from './sales-process-board.service';
import QMBox from './QMBox';

const SalesProcessBoard = () => {
  const { RESPONSE_STATUS } = constants;
  const { id } = useParams();
  const stageRef = useRef(null);
  const stageNameRef = useRef({});
  const [stageColumns, setStageColumns] = useState([]);
  const [qmCheckpointData, setQmCheckpointData] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [stageOrder, setStageOrder] = useState(0);
  const [stageData, setStageData] = useState({});
  const [allowTooltip, setAllowTooltip] = useState({});
  const [data, setData] = useState({
    qmObj: {},
    checkpointObj: {}
  })
  const [isOpen, setIsOpen] = useState({
    stage: false,
    qm: false,
    checkpoint: false,
  })
  const [arrowIconState, setArrowIconState] = useState({
    scrollState: 0,
    scrollDirection: 'right',
    showArrowIcon: false,
  });
  // const [checkpointOrder, setCheckpointOrder] = useState(0)

  useEffect(() => {
    getStages();
    getAllQm();
  }, []);

  useEffect(() => {
    getCheckpoint();
  }, [stageColumns.length]);

  useEffect(() => {
    if (stageRef?.current?.scrollWidth > stageRef?.current?.offsetWidth) {
      setArrowIconState((prevState) => ({ ...prevState, showArrowIcon: true }));
    } else {
      setArrowIconState((prevState) => ({ ...prevState, showArrowIcon: false }));
    }
    handleStageRef();
  }, [stageColumns]);

  useEffect(() => {
    if (stageRef?.current) {
      if (arrowIconState.scrollState === stageRef?.current?.scrollWidth - stageRef?.current?.offsetWidth) {
        setArrowIconState((prevState) => ({ ...prevState, scrollDirection: 'left' }));
      } else if (arrowIconState.scrollState === 0) {
        setArrowIconState((prevState) => ({ ...prevState, scrollDirection: 'right' }));
      }
    }
  }, [arrowIconState.scrollState]);

  useEffect(() => {
    if (
      !!qmCheckpointData &&
      Object.keys(qmCheckpointData).length &&
      qmCheckpointData?.checkpointData 
    ) {
      // setCheckpointOrder(qmCheckpointData?.checkpointData?.length);
      handleQmCheckpointData();
    }
  }, [qmCheckpointData]);

  const getStages = async () => {
    const response = await getStagesApi(id);
    if (response?.status === RESPONSE_STATUS.SUCCESS_MESSAGE) {
      if (response.result) {
        const stageArr = [...response?.result];
        stageArr.forEach((element) => {
          if (element.qm && element.qm.length === 0)
            element.qm = [];
        });
        stageArr.sort((a, b) => a.order - b.order);
        setStageColumns(stageArr);
      }
    }
  };

  const deleteStage = async () => {
    const response = await deleteStageApi(stageData?.stageId, id);
    if (response?.status === RESPONSE_STATUS.SUCCESS_MESSAGE) {
      setOpenDeleteModal(false);
      getStages(id);
      setStageData({});
    } else {
      toasterService(response.message);
    }
  };

  const getAllQm = async () => {
    const response = await getAllQmApi(id);
    if (response?.status === RESPONSE_STATUS.SUCCESS_MESSAGE && response.data && response.data.length) {
      setQmCheckpointData({ ...qmCheckpointData, qmData: response.data });
    }
  };

  const getCheckpoint = async () => {
    const arr = [];
    const data = await Promise.all(stageColumns.map((item) => getAllCheckpoint(item.id)));
    if (data && data.length) {
      data.map((item) => item?.data?.map((value) => value.defaultQM && arr.push(value)));
    }
    arr.sort((a, b) => a.position - b.position);
    setQmCheckpointData((prevState) => ({ ...prevState, checkpointData: arr }));
  };

  const updateCheckpointOrder = async (data) => {
    const response = await updateCheckpointOrderApi(data);
    if (response) {
      getCheckpoint();
    }
  };

  const handleQmCheckpointData = () => {
    const stageObj = {};
    qmCheckpointData?.qmData?.map((dataQm) => {
      qmCheckpointData?.checkpointData?.map((cpData) => {
        if (dataQm.id === cpData.defaultQM) {
          if (stageObj[cpData.stageId]) {
            if (stageObj[cpData.stageId].qm.includes(dataQm) === false) {
              stageObj[cpData.stageId].qm = [...stageObj[cpData.stageId].qm, dataQm];
            }
            stageObj[cpData.stageId].checkpoints = [...stageObj[cpData.stageId].checkpoints, cpData];
          } else {
            stageObj[cpData.stageId] = {
              qm: [dataQm],
              checkpoints: [cpData],
            };
          }
        }
        return null;
      });
      return null;
    });

    const sampleArr = {};
    Object.keys(stageObj).map((item) => {
      stageObj[item].qm.map((qm) => {
        const checkpoints = [];
        stageObj[item].checkpoints.map((check) => {
          if (qm.id === check.defaultQM) {
            checkpoints.push(check);
          }
          return null;
        });
        if (sampleArr[item] && sampleArr[item].length) {
          sampleArr[item].push({
            ...qm,
            checkpoints,
          });
        } else {
          sampleArr[item] = [];
          sampleArr[item].push({
            ...qm,
            checkpoints,
          });
        }
        return null;
      });
      return null;
    });

    const newData = stageColumns.map((stageData) => {
      if (sampleArr[stageData.id]) stageData.qm = sampleArr[stageData.id];
      else stageData.qm = [];
      return stageData;
    });

    newData.map(
      (_val) =>
        _val.qm &&
        _val.qm.map((ele) => {
          ele.stageId = _val.id;
          return null;
        })
    );

    newData.map((ele) => {
      const data = ele.qm.map((_val) => {
        qmCheckpointData.checkpointData.map((cpData) => {
          if (_val.id === cpData.defaultQM && _val.stageId === cpData.stageId) {
            _val.index = cpData.qmPosition;
          }
          return null;
        });
        return _val;
      });
      data.sort((a, b) => a.index - b.index);
      ele.qm = data;
      return null;
    });

    setStageColumns(newData);
  };

  const handleStageRef = () => {
    const refObj = {};
    for (let index = 0; index < Object.keys(stageNameRef).length; index += 1) {
      if (stageNameRef[index]?.scrollWidth > stageNameRef[index]?.offsetWidth) {
        refObj[stageNameRef[index]?.innerText] = true;
        setAllowTooltip(refObj);
      } else {
        refObj[stageNameRef[index]?.innerText] = false;
        setAllowTooltip(refObj);
      }
    }
  };

  const onDragEnd = (result, stageColumns, setStageColumns) => {
    if (!result.destination) return;
    const { source, destination, draggableId } = result;
    const sourceIndex = source.index;
    const destIndex = destination.index;

    // code to drag checkpoint
    if (result.type === 'droppableCheckpoint') {
      const sourceQMId = source.droppableId.split('/')[0];
      const sourceStageId = source.droppableId.split('/')[1];
      const destQMId = destination.droppableId.split('/')[0];
      const destStageId = destination.droppableId.split('/')[1];

      if (sourceQMId && destQMId && sourceStageId && destStageId) {
        updateCheckpointOrder({
          id: draggableId,
          stageId: destStageId,
          defaultQM: destQMId,
          salesProcessId: id,
          sourceIndex,
          destIndex,
          sourceQMId,
          sourceStageId,
        });
      }
    } else {
      // code to drag QM
      const qmId = draggableId.split('/')[0];
      const checkpointIdArr = [];
      qmCheckpointData?.checkpointData?.filter((_val) => {
        if (_val.defaultQM === qmId) {
          checkpointIdArr.push(_val.id);
        }
        return null;
      });
      updateCheckpointOrder({
        id: checkpointIdArr.length ? checkpointIdArr : [],
        stageId: source.droppableId !== destination.droppableId ? destination.droppableId : source.droppableId,
        defaultQM: qmId,
        salesProcessId: id,
        sourceIndex,
        destIndex,
        sourceQMId: qmId,
        sourceStageId: source.droppableId,
      });
    }
  };

  const slideStage = (direction) => {
    if (stageRef?.current) {
      const container = stageRef?.current;
      let scrollCompleted = 0;
      const slideTimer = setInterval(() => {
        if (direction === 'left') {
          container.scrollLeft -= 40;
        } else {
          container.scrollLeft += 40;
        }
        scrollCompleted += 10;
        if (scrollCompleted >= 100) {
          window.clearInterval(slideTimer);
        }
        setArrowIconState((prevState) => ({ ...prevState, scrollState: container.scrollLeft }));
      }, 50);
    }
  };

  const handleCreateStageModalOpen = (index) => {
    setStageOrder(index + 2);
    setIsOpen({ ...isOpen, stage: true })
  };

  const handleCreateCheckpoint = () => {
    setData({...data, checkpointObj: {}});
    setIsOpen({ ...isOpen, checkpoint: true })
  };

  const handleEditCheckpoint = (checkpointObj) => {
    setData({...data, checkpointObj})
    setIsOpen({ ...isOpen, checkpoint: true })
  }

  const handleEditQm = (dataQm) => {
    setData({...data, qmObj: dataQm})
    setIsOpen({ ...isOpen, qm: true })
  }

  const handleEditStageModalOpen = (stageObj) => {
    setStageData(stageObj);
    setIsOpen({ ...isOpen, stage: true })
  };

  const handleDeleteModal = (stageObj) => {
    setStageData(stageObj);
    setOpenDeleteModal(true);
  };

  return (
    <>
      <SalesProcess>
        <Header qmData={data.qmObj} setQmData={setData} openQm={isOpen.qm} setOpenQm={setIsOpen} getAllQm={getAllQm} />
        <HorizontalRuler />
        {stageColumns.length !== 0 ? (
          <SalesProcessData>
            <Grid container className="stageOverflow" ref={stageRef}>
              <DragDropContext onDragEnd={(result) => onDragEnd(result, stageColumns, setStageColumns)}>
                {stageColumns.map((column, index) => {
                  return (
                    <>
                      <Grid item xs={2} key={column?.id}>
                        {index === 0 ? (
                          <Box
                            className="stageName firstStageName"
                            onClick={() =>
                              handleEditStageModalOpen({ stageId: column.id, stageName: column.stageName })
                            }
                          >
                            {stageNameRef[index]?.scrollWidth > stageNameRef[index]?.offsetWidth &&
                            allowTooltip &&
                            allowTooltip[stageNameRef[index]?.innerText] ? (
                              <CustomToolTip placement="bottom" name={column?.stageName}>
                                <span
                                  ref={(ref) => {
                                    stageNameRef[index] = ref;
                                  }}
                                  className={!column?.qm?.length && 'showTooltip'}
                                >
                                  {column?.stageName}
                                </span>
                              </CustomToolTip>
                            ) : (
                              <span
                                ref={(ref) => {
                                  stageNameRef[index] = ref;
                                }}
                              >
                                {column?.stageName}
                              </span>
                            )}
                            {!column?.qm?.length && (
                              <div className="deleteIcon">
                                <Avatar>
                                  <DeleteIcon
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleDeleteModal({ stageId: column.id, stageName: column.stageName });
                                    }}
                                  />
                                </Avatar>
                              </div>
                            )}
                          </Box>
                        ) : (
                          <Box
                            className="stageName stageTrain"
                            onClick={() =>
                              handleEditStageModalOpen({ stageId: column.id, stageName: column.stageName })
                            }
                          >
                            {stageNameRef[index]?.scrollWidth > stageNameRef[index]?.offsetWidth &&
                            allowTooltip &&
                            allowTooltip[stageNameRef[index]?.innerText] ? (
                              <CustomToolTip placement="bottom" name={column?.stageName}>
                                <span
                                  ref={(ref) => {
                                    stageNameRef[index] = ref;
                                  }}
                                  className={!column?.qm?.length && 'showTooltip'}
                                >
                                  {column.stageName}
                                </span>
                              </CustomToolTip>
                            ) : (
                              <span
                                ref={(ref) => {
                                  stageNameRef[index] = ref;
                                }}
                              >
                                {column?.stageName}
                              </span>
                            )}

                            {!column?.qm?.length && (
                              <div className="deleteIcon">
                                <Avatar>
                                  <DeleteIcon
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleDeleteModal({ stageId: column.id, stageName: column.stageName });
                                    }}
                                  />
                                </Avatar>
                              </div>
                            )}
                          </Box>
                        )}
                        <Droppable droppableId={column?.id} key={column?.id} type="droppableQM">
                          {(provided, snapshot) => {
                            return (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={{
                                  background: snapshot.isDraggingOver && 'white',
                                }}
                              >
                                {column?.qm?.length ? (
                                  column?.qm.map((item, index) => {
                                    return (
                                      <QMBox
                                        key={item.id}
                                        index={index}
                                        qmData={item}
                                        stageId={item.stageId}
                                        getCheckpoint={getCheckpoint}
                                        handleEditCheckpoint={handleEditCheckpoint}
                                        handleEditQm={handleEditQm}
                                      />
                                    );
                                  })
                                ) : (
                                  <></>
                                )}
                                {provided.placeholder}
                                <ButtonAtom
                                  ButtonName="ADD CHECKPOINT"
                                  variant="text"
                                  buttonType="text"
                                  startIcon={<AddIcon />}
                                  className="addCheckpointButton"
                                  onClick={handleCreateCheckpoint}
                                />
                              </div>
                            );
                          }}
                        </Droppable>
                      </Grid>

                      {stageColumns?.length >= 1 && index < stageColumns?.length - 1 && (
                        <div className="addStageIcon">
                          <Avatar onClick={() => handleCreateStageModalOpen(index)}>
                            <AddIcon />
                          </Avatar>
                        </div>
                      )}
                    </>
                  );
                })}
              </DragDropContext>
              <Grid item xs className="createStageButton">
                <ButtonAtom
                  buttonType="submit"
                  ButtonName="CREATE NEW STAGE"
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={() => handleCreateStageModalOpen(stageColumns?.length - 1)}
                />
              </Grid>
            </Grid>
            {arrowIconState.showArrowIcon && (
              <>
                {arrowIconState.scrollState !== stageRef?.current?.scrollWidth - stageRef?.current?.offsetWidth &&
                  arrowIconState.scrollDirection === 'right' && (
                    <ArrowIconStyle onClick={() => slideStage('right')} className="rightArrowIcon">
                      <ArrowRightIcon className="arrowIcon" />
                    </ArrowIconStyle>
                  )}
                {arrowIconState.scrollState !== 0 && arrowIconState.scrollDirection === 'left' && (
                  <ArrowIconStyle onClick={() => slideStage('left')} className="leftArrowIcon">
                    <ArrowLeftIcon className="arrowIcon" />
                  </ArrowIconStyle>
                )}
              </>
            )}
          </SalesProcessData>
        ) : (
          <EmptySalesProcess>
            <div>
              <ButtonAtom
                buttonType="submit"
                ButtonName="CREATE NEW STAGE"
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={() => handleCreateStageModalOpen(stageColumns.length - 1)}
              />
            </div>
            <ButtonAtom
              ButtonName="ADD CHECKPOINT"
              variant="text"
              startIcon={<AddIcon />}
              onClick={handleCreateCheckpoint}
            />
          </EmptySalesProcess>
        )}
      </SalesProcess>
      {isOpen.stage && (
        <CreateStage
          opend={isOpen.stage}
          setOpend={setIsOpen}
          order={stageColumns.length === 0 ? stageColumns.length + 1 : stageOrder}
          salesProcessId={id}
          getStages={getStages}
          stageData={stageData}
          setStageData={setStageData}
          getAllQm={getAllQm}
          getCheckpoint={getCheckpoint}
        />
      )}
      {isOpen.checkpoint && (
        <CreateCheckPoint
          openCheckpoint={isOpen.checkpoint}
          setOpenCheckpoint={setIsOpen}
          getCheckpoint={getCheckpoint}
          stageColumns={stageColumns}
          qmCheckpointData={qmCheckpointData}
          checkpointObj={data.checkpointObj}
          // checkpointOrder={checkpointOrder}
          // setCheckpointOrder={setCheckpointOrder}
        />
      )}
      {openDeleteModal && (
        <Delete deleteOpen={openDeleteModal} setDeleteOpen={setOpenDeleteModal} onClick={deleteStage} name="stage" title={stageData.stageName} setStageData={setStageData}/>
      )}
    </>
  );
};

export default SalesProcessBoard;

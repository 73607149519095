import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, InputAdornment, Paper, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import Header from '../../../components/Header/Header';
import { toasterService } from '../../../helper';

// ----------------------------------------------------------------------

export default function ResetPassword() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const email = localStorage.getItem('logined_email');

  const setPasswordSchema = Yup.object().shape({
    otp: Yup.string().required('OTP is required'),
    password: Yup.string()
      .required('Password is required')
      .matches(
        /^((?=(.*[^A-Za-z0-9]){1,})(?=(.*[A-Z]){1,})(?=(.*\d){1,})(?=.*[a-z]){1,}).{8,}/,
        'Password is not valid'
      ),
    confirmPass: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Password is not valid')
      .required('Confirm password is required'),
  });

  const defaultValuesSetPassword = {
    otp: '',
    password: '',
    confirmPass: '',
  };

  const methodsSetPassword = useForm({
    resolver: yupResolver(setPasswordSchema),
    defaultValuesSetPassword,
  });

  const {
    handleSubmit: handleSubmitSetPassword,
    setError: setErrorIsPassword,
    formState: { isSubmitting: isSubmittingSetPassword },
  } = methodsSetPassword;

  const setPassword = async (value) => {
    try {
      const res = await Auth.forgotPasswordSubmit(email, value.otp, value.password);
      if (res === 'SUCCESS') {
        navigate('/');
      }
    } catch (error) {
      setErrorIsPassword('otp', { message: error.message }, { type: 'focus' }, { shouldFocus: true });
    }
  };

  const resendOtp = async () => {
    const res = await Auth.forgotPassword(email);
    if (Object.keys(res.CodeDeliveryDetails).length) {
      toasterService("Code has been sent successfully");
    }
  };

  const PaperCustom = styled(Paper)(({ theme }) => ({
    position: 'absolute',
    width: '40%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '22px',
    border: '1px solid #ececec',
    '& .MuiTypography-root': {
      marginBottom: 20,
      textAlign: 'center',
      fontWeight: 500,
    },
    '& .resend': {
      border: `1px solid ${theme.palette.grey[700]}`,
      color: theme.palette.grey[700],
      '&:hover': {
        borderColor: theme.palette.primary.themeColor,
        background: theme.palette.primary.mainLight,
        color: theme.palette.primary.themeColor,
      },
    }
  }));

  return (
    <>
      <Header />
      <PaperCustom>
        <FormProvider methods={methodsSetPassword} onSubmit={handleSubmitSetPassword(setPassword)}>
          <Typography variant="subtitle1">Set Password</Typography>
          <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={3}>
            <RHFTextField name="otp" label="Enter your OTP" />
            <RHFTextField
              name="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
                autoComplete: 'new-password',
                form: {
                  autoComplete: 'off'
                },
              }}
            />
            <RHFTextField type="password" name="confirmPass" label="Confirm Passowrd" />
            <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmittingSetPassword}>
              Set Password
            </LoadingButton>
            <LoadingButton className="resend" fullWidth size="large" variant="outlined" loading={isSubmittingSetPassword} onClick={resendOtp}>
              Resend OTP
            </LoadingButton>
          </Stack>
        </FormProvider>
      </PaperCustom>
    </>
  );
}

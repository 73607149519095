import AuthService from "./authService";
// import ToasterService from "./toasterService";

export default {
  requestHandler(request) {
    return request;
  },
  errorHandler(error) {
    if (error && error.response.status === 401) {
      // ToasterService(error.response.data.message, 4, "userlogout");
      AuthService.signOut();
    } else if (error && error.response.status === 403) {
      // ToasterService(error.response.data.message, 4, "userlogout");
    }
    const res = {
      error:
        error.response && error.response.data ? error.response.data : error,
      status: false,
    };
    return Promise.reject(res);
  },
  successHandler(response) {
    return {
      status: true,
      data: response.data,
      ...(response.data.message && { message: response.data.message }),
    };
  },
};

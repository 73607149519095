// theme
import ThemeProvider from './theme';

// components
import ScrollToTop from './components/ScrollToTop';
import LoginScreen from './login/login';
import Toaster from './components/ToastMessage/toastAtom';
// ----------------------------------------------------------------------
function App() {
  return (
    <ThemeProvider>
      <ScrollToTop />
      <Toaster />
      <LoginScreen />
    </ThemeProvider>
  );
}
export default App;

import React from 'react';
import { Dialog, Divider, DialogTitle } from '@mui/material';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    background: 'rgba(22, 28, 36, 0.50) !important',
  },
  '&.DeleteModal': {
    '& .MuiPaper-root': {
      '& .MuiDialogContent-root': {
        padding: '0px',
        textAlign: 'center',
        '& .MuiGrid-root': {
          display: 'flex',
        }
      },
    },
  },
  '& .MuiDialog-container': {
    '& .MuiPaper-root': {
      borderRadius: '4px',
      color: theme.palette.text.primary,
      overflow: 'hidden',
    },
  },
  '& .MuiTypography-root': {
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: '500',
  },
  '& .MuiDialogContent-root': {
    overflowY: 'auto',
    padding: '50px 84px',
    [theme.breakpoints.down('sm')]: {
      padding: '20px',
    },
    maxHeight: 'calc(100vh - 220px)',
  },
  '& .MuiDialogActions-root': {
    padding: '8px 0px',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiButtonBase-root': {
      borderRadius: 4,
    },
  },

  '&.MultiGridForm': {
    '& .MuiDialogContent-root': {
      padding: '15px 35px',
    },
  },
}));

const ModalCustom = (props) => {
  const { isDeleteModal, isMultiGridForm, openDialog, dialogHeading, onClose, children } = props;

  return (
    <>
      <CustomDialog
        fullWidth
        maxWidth={isMultiGridForm ? 'md' : 'sm'}
        open={openDialog}
        onClose={() => {
          onClose();
        }}
        className={isDeleteModal ? 'DeleteModal' : isMultiGridForm && 'MultiGridForm'}
      >
        <DialogTitle>{dialogHeading}</DialogTitle>
        <Divider />
        {children}
      </CustomDialog>
    </>
  );
};

ModalCustom.propTypes = {
  openDialog: PropTypes.bool,
  children: PropTypes.node.isRequired,
  isDeleteModal: PropTypes.bool,
  isMultiGridForm: PropTypes.bool,
  dialogHeading: PropTypes.string,
  onClose: PropTypes.func,
};

ModalCustom.defaultProps = {
  openDialog: false,
  isDeleteModal: false,
  isMultiGridForm: false,
  dialogHeading: '',
  onClose: () => { },
};

export default React.memo(ModalCustom);

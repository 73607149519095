import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';

const CloseButton = styled(Button)(({ theme }) => ({
  background: '#E8E8E8',
  color: theme.palette.primary.silver,
  '&:hover': {
    background: '#E8E8E8',
  },
}));

const DeleteButton = styled(Button)(() => ({
  background: '#CD3222',
  color: '#fff',
  '&:hover': {
    background: '#CD3222',
  },
}));

const CustomTextButton = styled(Button)(() => ({
  fontWeight: 600,
  fontSize: '14px',
  color: 'rgba(0, 0, 0, 0.4)',
  padding: '0px 8px',
  gap: '8px',
  display: 'flex',
  alignItems: 'center',
  '&:hover': {
    boxShadow: 'none',
  },
  '& .MuiButton-startIcon': {
    marginRight: '0px',
    '& svg': {
      fontSize: '16px',
      fill: 'rgba(0, 0, 0, 0.4)',
    },
  },
}));
export default function ButtonAtom(props) {
  const { name, type, onClick, buttonType, startIcon, disabled, variant, loading, ButtonName, ...other } = props;
  return (
    <>
      {buttonType === 'submit' && (
        <LoadingButton
          loading={loading}
          variant={variant}
          name={name}
          type={type}
          onClick={onClick}
          startIcon={startIcon}
          disabled={disabled}
          {...other}
        >
          {ButtonName}
        </LoadingButton>
      )}
      {buttonType === 'cancel' && (
        <CloseButton variant={variant} name={name} type={type} onClick={onClick} startIcon={startIcon} {...other}>
          {ButtonName}
        </CloseButton>
      )}
      {buttonType === 'delete' && (
        <DeleteButton variant={variant} name={name} type={type} onClick={onClick} startIcon={startIcon} {...other}>
          {ButtonName}
        </DeleteButton>
      )}
      {variant === 'text' && (
        <CustomTextButton variant="text" name={name} type={type} onClick={onClick} startIcon={startIcon} {...other}>
          {ButtonName}
        </CustomTextButton>
      )}
    </>
  );
}
ButtonAtom.propTypes = {
  onClick: PropTypes.func,
  startIcon: PropTypes.object,
  variant: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  loading: PropTypes.bool,
  buttonType: PropTypes.string.isRequired,
  ButtonName: PropTypes.string.isRequired,
};

import PropTypes from 'prop-types';
import ButtonAtom from './buttonAtom';

const ModalButtonAtom = (props) => {
  const { onClose, buttonSubmitName, isDeleteModal, onSubmitButton, isSubmitting } = props;
  return (
    <>
      <ButtonAtom
        type="button"
        buttonType="cancel"
        variant="contained"
        color="primary"
        onClick={() => {
          onClose();
        }}
        ButtonName="Cancel"
      />
      <ButtonAtom
        ButtonName={buttonSubmitName}
        variant="contained"
        cclass="saveBtn"
        loading={isSubmitting}
        buttonType={isDeleteModal ? 'delete' : 'submit'}
        type="submit"
        onClick={onSubmitButton}
      />
    </>
  );
};
ModalButtonAtom.propTypes = {
  onClose: PropTypes.func,
  onSubmitButton: PropTypes.func,
  buttonSubmitName: PropTypes.string,
  isDeleteModal: PropTypes.bool,
  isSubmitting: PropTypes.bool,
};

ModalButtonAtom.defaultProps = {
  buttonSubmitName: '',
  isDeleteModal: false,
  isSubmitting: false,
  onSubmitButton: () => {},
  onClose: () => {},
};
export default ModalButtonAtom;

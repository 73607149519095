import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useAtom } from 'jotai';
import { Typography, Grid, Avatar, Switch, Divider, IconButton, styled, Dialog } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import DeleteIcon from '@mui/icons-material/Delete';
import ButtonAtom from '../../components/Button/buttonAtom';
import { constants } from '../../constants.value';
import { RHFSelectbox, FormProvider, RHFTextField } from '../../components/hook-form';
import { RHFAutocomplete } from '../../components/hook-form/RHFAutocomplete';
import {
  removeCollaborator as removeCollaboratorApi,
  getCollaborator as getCollaboratorApi,
  getUserByData,
  inviteNewCollabotaor,
  editAccess,
} from './collaborator.service';
import { toasterService } from '../../helper/index';
import { inviteCollabrator } from '../../store';

const CustomSearchButton = styled(Grid)(({ theme }) => ({
  marginTop: '19px',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
  gap: 10,
  '& .textField': {
    width: '464px',
    '& .MuiFormControl-root': {
      '& .MuiFormLabel-root': {
        lineHeight: '1em',
        overflow: 'visible',
        color: theme.palette.primary.darkerGrey,
      },
      '& .MuiInputLabel-shrink': {
        lineHeight: 'inherit',
      },
      '& .MuiButtonBase-root': {
        width: '83px !important',
      },
      '& .MuiInputBase-adornedEnd': {
        padding: '4px',
        paddingRight: '120px',
        '& .MuiInputBase-input': {
          padding: '8px 0px 8px 12px',
        },
      },
    },
  },
  '& .buttonAtom': {
    '& .MuiButtonBase-root': {
      border: theme.palette.button.lightGreyBorder,
    },
  },
  '& .buttonAtomCopy': {
    '& .MuiButtonBase-root': {
      padding: '4px 27px',
    },
  },
  '& .customEditView': {
    width: '22%',
    position: 'absolute',
    right: '15px',
    '& .MuiInputBase-root': {
      borderRadius: '25px',
      padding: '2px 12px',

      '& .MuiSvgIcon-root': {
        marginLeft: 0,
      },
      '& .MuiListItemIcon-root': {
        minWidth: '25px',
        visibility: 'hidden',
      },
      '& .MuiSelect-select': {
        padding: '1px 0.2px !important',
        fontSize: '12px',
        position: 'relative',
        left: '2px',
        textOverflow: 'initial',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(0, 0, 0, 0.2)',
      },
    },
  },
}));

const HorizontalRuler = styled(Divider)(() => ({
  width: '100%',
  marginTop: '20px',
}));

const CustomPeopleAccess = styled(Grid)(({ theme }) => ({
  justifyContent: 'space-between',
  alignItem: 'center',
  marginTop: 20,
  '&.noCollabList': {
    justifyContent: 'center',
  },
  '& .avatartypo': {
    display: 'flex',
    alignItems: 'center',
    gap: 15,
  },
  '& .customEditDelete': {
    display: 'flex',
    gap: 20,
    alignItems: 'center',
    '& .MuiInputBase-root': {
      borderRadius: '25px',
      paddingLeft: '10px',
      '& .MuiSvgIcon-root': {
        marginLeft: 0,
      },
      '& .MuiSelect-select': {
        padding: '6px 5px',
        fontSize: '13px',
      },
      '& .MuiListItemIcon-root': {
        minWidth: '30px',
        visibility: 'hidden',
      },
    },
  },
  '& .deleteIcon': {
    width: '32px',
    height: '32px',
    backgroundColor: theme.palette.primary.red,
    '& .MuiSvgIcon-root': {
      width: '24px',
      color: theme.palette.common.white,
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.red,
      boxShadow: theme.customShadows.switch,
    },
  },
  '& .avatarImageMail': {
    width: '36px',
    height: '36px',
  },
}));

const CustomTypoSwitch = styled(Grid)(({ theme }) => ({
  justifyContent: 'space-between',
  padding: '0px 1px',
  marginTop: 20,
  marginBottom: 8,
  '& .anyoneWithLink': {
    fontSize: '14px',
    lineHeight: '24px',
    color: `${theme.palette.primary.silver}!important`,
  },
}));

const InviteModal = styled(Dialog)(() => ({
  '& .MuiBackdrop-root': {
    background: 'rgba(22, 28, 36, 0.50) !important',
  },
  '& .MuiDialog-paper': {
    boxShadow: '0px 8px 11px rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
    padding: '14px 20px 33px',
    maxWidth: 616,
    '& .MuiTypography-body1': {
      letterSpacing: '0.5px',
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
  '& .collaboratorsList': {
    marginTop: 39,
    '& .inviteUsersList': {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      maxHeight: 140,
      overflow: 'auto',
      paddingRight: 15,
      marginTop: 12,
      '& .MuiGrid-root': {
        marginTop: '3px',
        marginBottom: '5px'
      }
    },
  },
}));

export default function InviteCollabrator(props) {
  const { openInviteCollaboratorModal, setOpenInviteCollaboratorModal, collaboratorIcon, salesProcessId } = props;
  const [collaboratorEmail, setcollaboratorEmail] = useAtom(inviteCollabrator);
  const [anchorEl, setAnchorEl] = useState(null);
  const [userList, setUserList] = useState([]);
  const [searchMail, setSearchMail] = useState('');
  const open = Boolean(anchorEl);
  const { RESPONSE_STATUS } = constants;

  const handleClose = () => {
    setAnchorEl(null);
    setOpenInviteCollaboratorModal(false);
  };
  const getCollaborator = async () => {
    const response = await getCollaboratorApi(salesProcessId);
    if (response && !!response.result) {
      setcollaboratorEmail(response?.result);
    }
  };

  const removeCollaborator = async (id) => {
    if (id) {
      const response = await removeCollaboratorApi({ id, salesProcessId });
      toasterService(response.message);
      if (response.status === 200) {
        getCollaborator();
      }
    }
  };

  const defaultValues = {
    canEditSelect: '',
  };

  const defaultValuesUsers = {
    emailSearch: '',
    canEdit: false,
  };

  const validation = Yup.object().shape({
    canEditSelect: Yup.array().of(
      Yup.object().shape({
        id: Yup.string(),
      })
    ),
  });
  const methods = useForm({
    resolver: yupResolver(validation),
    defaultValues,
  });

  const EmailSearchSchema = Yup.object().shape({
    emailSearch: Yup.array().required('Email is required'),
    canEdit: Yup.bool(),
  });

  const methodAutoComplete = useForm({
    resolver: yupResolver(EmailSearchSchema),
    defaultValuesUsers,
  });

  const { handleSubmit, watch, setValue } = methods;
  const { watch: watchInvite, setValue: setInviteValue, handleSubmit: handleSubmitUserBySearch } = methodAutoComplete;

  useEffect(() => {
    if (openInviteCollaboratorModal) {
      setAnchorEl(collaboratorIcon);
      getCollaborator();
      setInviteValue('canEdit', false);
    }
  }, []);

  useEffect(() => {
    if (collaboratorEmail.length) {
      collaboratorEmail.forEach((element, i) => {
        setValue(`canEditSelect.${i}.${element.id}`, element.canEdit);
      });
    }
  }, [collaboratorEmail, setValue]);

  const handleCopySubmit = async (data) => {
    console.log(data, 'data');
  };

  const names = [
    { name: 'Can Edit', value: true },
    { name: 'Can View', value: false },
  ];

  const getAllUsersData = async () => {
    const users = await getUserByData(searchMail);
    setUserList([]);
    if (users.code === 200) {
      const optionArray = [];
      users?.data?.forEach((option) => {
        optionArray.push(option);
      });
      setUserList(optionArray);
    }
  };

  useEffect(() => {
    if (!!searchMail && searchMail.length >= 4 && searchMail.length < 5) {
      getAllUsersData();
    }
  }, [searchMail]);

  const handleClick = async (values) => {
    let payload;
    const exist = userList.filter((val) => val.email === values?.emailSearch[0]);
    if (values?.emailSearch[0].id || exist.length) {
      payload = {
        userId: values?.emailSearch[0].id ? values?.emailSearch[0].id : exist[0].id,
        canEdit: values?.canEdit,
      };
    } else {
      payload = {
        userEmail: values?.emailSearch[0],
        canEdit: values?.canEdit,
      };
    }
    if (!!payload.userId || !!payload.userEmail) {
      const response = await inviteNewCollabotaor(salesProcessId, payload);
      if (response?.code === RESPONSE_STATUS.SUCCESS) {
        toasterService(response.message);
        document.getElementById('inviteButton').style.background = 'rgba(252, 185, 91, 0.5)';
        getCollaborator();
        getAllUsersData();
        setInviteValue("emailSearch", '');
      }
    }
  };
  const editCollabrator = async (id) => {
    const canEditVal = await watch('canEditSelect');
    const selectedData = await canEditVal.filter((val) => Object.keys(val)[0] === id);
    if (selectedData.length) {
      const payload = {
        canEdit: Object.values(selectedData[0])[0],
        salesProcessId,
      };
      const response = await editAccess(id, payload);
      if (response?.code === RESPONSE_STATUS.SUCCESS) {
        toasterService(response.message);
      }
    }
  };

  const handleFocusEvent = () => {
    if (watchInvite('emailSearch')?.length) {
      document.getElementById('inviteButton').style.background = '#FCB95B';
    }
  };

  return (
    <InviteModal open={open} onClose={handleClose}>
      <Typography variant="body1" gutterBottom>
        Share this design
      </Typography>

      <FormProvider methods={methodAutoComplete} onSubmit={handleSubmitUserBySearch(handleClick)}>
        <CustomSearchButton container>
          <Grid item>
            <RHFAutocomplete
              name="emailSearch"
              options={userList}
              label="Add emails"
              multiple
              onFocus={handleFocusEvent}
              handleChange={(e) => {
                if (e.target.value) {
                  return setSearchMail(e.target.value);
                }
                return setSearchMail('');
              }}
              className="textField"
              InputProps={
                <div className="customEditView">
                  <RHFSelectbox isInviteModal name="canEdit" menus={names} />
                </div>
              }
            />
          </Grid>
          <Grid item className="buttonAtom">
            <ButtonAtom
              disabled={!watchInvite('emailSearch')?.length}
              type="submit"
              id="inviteButton"
              ButtonName="INVITE"
              buttonType="submit"
              variant="contained"
            />
          </Grid>
        </CustomSearchButton>
      </FormProvider>

      <Grid container className="collaboratorsList" item>
        <Typography className="heading">People with access</Typography>
        <div className="inviteUsersList">
          {!!collaboratorEmail && collaboratorEmail.length > 1 ? (
            collaboratorEmail.map(
              (_item, i) =>
                _item.isOwner === false && (
                  <CustomPeopleAccess key={i} container item>
                    <Grid item className="avatartypo">
                      <Avatar className="avatarImageMail">{_item.user.firstName?.charAt(0).toUpperCase()}</Avatar>
                      <Typography>{_item?.user?.email}</Typography>
                    </Grid>
                    <Grid item className="customEditDelete">
                      <FormProvider methods={methods} onSubmit={handleSubmit(editCollabrator)}>
                        <RHFSelectbox
                          isInviteModal
                          name={`canEditSelect.${i}.${_item.id}`}
                          menus={names}
                          onChange={() => editCollabrator(_item.id)}
                        />
                      </FormProvider>
                      <IconButton
                        onClick={() => removeCollaborator(_item.id)}
                        className="deleteIcon"
                        aria-label="delete"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </CustomPeopleAccess>
                )
            )
          ) : (
            <CustomPeopleAccess container item className="noCollabList">
              <Typography className="noCollabText">No Collaborators Yet!</Typography>
            </CustomPeopleAccess>
          )}
        </div>
      </Grid>

      <HorizontalRuler />

      <CustomTypoSwitch container>
        <Grid item>
          <Typography>Share to Web</Typography>
          <Typography className="anyoneWithLink">Anyone with the link can view</Typography>
        </Grid>
        <Grid item>
          <Switch defaultChecked />
        </Grid>
      </CustomTypoSwitch>
      <FormProvider methods={methods} onSubmit={handleSubmit(handleCopySubmit)}>
        <CustomSearchButton container>
          <Grid item>
            <RHFTextField
              id="figma"
              variant="outlined"
              disabled
              placeholder="figma.com/file/ip474fgybc7ryv/Iseei..."
              name="figma"
              readOnly
              className="textField"
            />
          </Grid>
          <Grid item className="buttonAtomCopy">
            <ButtonAtom ButtonName="COPY" buttonType="submit" variant="contained" />
          </Grid>
        </CustomSearchButton>
      </FormProvider>
    </InviteModal>
  );
}
InviteCollabrator.propTypes = {
  openInviteCollaboratorModal: PropTypes.bool,
  setOpenInviteCollaboratorModal: PropTypes.func,
  collaboratorIcon: PropTypes.object,
  salesProcessId: PropTypes.string,
};

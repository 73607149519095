export default function Switch(theme) {
    return {
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    '&.Mui-checked': {
                        color: `${theme.palette.primary.themeColor}!important`,
                        '&+.MuiSwitch-track': {
                            opacity: '1 !important',
                            backgroundColor: `${theme.palette.button.lightSkinColor} !important`
                        },
                        "& .MuiSwitch-thumb": {
                            boxShadow: theme.customShadows.switch
                        }
                    }
                }
            }
        }
    }

}
import { constants } from '../../constants.value';
import { httpService } from '../../helper';

export function createCheckpoint(data) {
  return httpService()
    .post(`${constants.API.CHECKPOINT}`, data)
    .then((response) => response.data)
    .catch((error) => error.response);
}

export function getAllCheckpoint(stageId) {
  return httpService()
      .get(`${constants.API.CHECKPOINT}/${stageId}`)
      .then((response) => response.data)
      .catch((error) => error.response);
};

export function deleteCheckpoint(id, data) {
  return httpService()
    .delete(`${constants.API.CHECKPOINT}/${id}?stageId=${data}`)
    .then((response) => response.data)
    .catch((error) => error.response);
}

export function editCheckpointData(checkPointId, data) {
  return httpService()
    .put(`${constants.API.CHECKPOINT}/${checkPointId}`, data)
    .then((response) => response)
    .catch((error) => error.response);
}

// ----------------------------------------------------------------------

export default function CssBaseline(theme) {
  return {
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          margin: 0,
          padding: 0,
          boxSizing: 'border-box',
          '&::-webkit-scrollbar': {
            width: '4px',
            height: '5px',
          },
          '&::-webkit-scrollbar-track': {
            borderRadius: 10,
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: 10,
            background: `linear-gradient(to top, ${theme.palette.primary.greyLightShade} calc(100% - 44px), ${theme.palette.grey[400]} 44px)`,
          },
        },
        html: {
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
        },
        body: {
          width: '100%',
          height: '100%',
          '& p.Mui-error': {
            position: 'absolute',
            top: '90%',
          },
        },
        '#root': {
          width: '100%',
          height: '100%',
        },
        input: {
          '&[type=number]': {
            MozAppearance: 'textfield',
            '&::-webkit-outer-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
            '&::-webkit-inner-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
          },
        },
        img: {
          display: 'block',
          maxWidth: '100%',
        },
      },
    },
  };
}

import { useState, useEffect, useRef } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { Box } from '@mui/material';
import { PropTypes } from 'prop-types';
import Checkpoint from './Checkpoint';
import CustomToolTip from '../../components/Tooltip/CustomToolTip';
import { QmHeading } from './SalesProcessBoard.style';

const QMBox = (props) => {
  const { qmData, index, stageId, getCheckpoint, handleEditCheckpoint,handleEditQm } = props;
  const qmRef = useRef(null);
  const [allowTooltip, setAllowTooltip] = useState(false);
  const qmId = qmData?.id.concat(`/${stageId}`);

  useEffect(() => {
    if (qmRef?.current?.scrollWidth > qmRef?.current?.offsetWidth) {
      setAllowTooltip(true);
    } else {
      setAllowTooltip(false);
    }
  }, [qmData]);

  return (
    <Draggable key={qmId} draggableId={qmId} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          key={qmId}
          style={{
            backgroundColor: snapshot.isDragging && 'white',
            ...provided.draggableProps.style,
          }}
        >
          <Box className="qmContainer" >
            <QmHeading {...provided.dragHandleProps} onClick={
              () => handleEditQm(qmData)
            }>
              <div className="qmAbbreviation">{qmData.qmAbbreviation}</div>
              <div className="qmName">
                {allowTooltip ? (
                  <CustomToolTip placement="bottom" name={qmData.qmName}>
                    <span ref={qmRef}>{qmData.qmName}</span>
                  </CustomToolTip>
                ) : (
                  <span ref={qmRef}>{qmData.qmName}</span>
                )}
              </div>
            </QmHeading>
            <Droppable droppableId={qmId} type="droppableCheckpoint">
              {(provided) => (
                <div>
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {qmData?.checkpoints?.map((check, index) => (
                      <Checkpoint checkpointObj={check} key={check.id} index={index} getCheckpoint={getCheckpoint} handleEditCheckpoint={handleEditCheckpoint}  />
                    ))}
                  </div>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </Box>
          {provided.placeholder}
        </div>
      )}
    </Draggable>
  );
};

QMBox.propTypes={
  qmData: PropTypes.object,
  getCheckpoint: PropTypes.func,
  handleEditCheckpoint: PropTypes.func,
  handleEditQm: PropTypes.func,
  index: PropTypes.number,
  stageId: PropTypes.string
}
export default QMBox;
